@media screen and (max-width: 2800px) {
  .comment_counter {
    color: #ff02ff;
    font-weight: normal;
  }
  .community_uploadRecentIcon {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  #community_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
    border: solid 4px #00ee13;
  }
  #community_profileContainer {
    width: 200px;
    top: -25px;
    height: 45px;
    right: 60px;
    border: solid 4px #00ee13;
  }
  .community_profileMenuOptions {
    font-size: 17px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 95%;
    height: 60vh;
    margin-top: 40px;
    justify-content: flex-start;
    /* margin-left: 5px; */
  }

  #myinfo_togglemenu {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 280px; */
    /* height: 60px; */
    width: 252px;
    height: 76px;
    /* bottom: -20px; */
    bottom: -40px;
    left: 5.3vw;
    border: solid 4px #00ee13;
  }
  #community_side_detail {
    display: flex;
    width: 170px;
    height: 400px;
    right: -100px;
    bottom: 180px;
  }
  .pagination {
    width: 80vw;
    padding-left: 5vw;
    display: flex;
    /* font-family: "Alfredo"; */
    font-size: 14px;
    font-weight: 600;
  }

  .pagination > li {
    margin: 0 15px;
  }

  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 17px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }

  .pagination_kor {
    /* position: absolute; */
    /* bottom: 12vh; */
    /* right: 5vw; */
    width: 500px;
    display: flex;
    justify-content: space-around;
    /* margin-right: 287px; */
  }
  .pagination_kor_btn {
    border: 3px solid #00ee13;
    padding: 3px 15px;
    font-size: 17px;
    font-weight: bold;
    margin: 0 5px;
  }
  .kor_list {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-right: 5.6vw;
    margin-left: 13px;
    padding: 7px 15px;
    color: #1a1a1a;
    background-color: #00ee13;
  }
  /* #community_writeBtn { */
  /* width: 125px; */
  /* height: 45px; */
  /* bottom: 0; */
  /* position: absolute; */
  /* bottom: 4vh; */
  /* right: 5.5vw; */
  /* font-size: 17px; */
  /* } */
  .community_content_box {
    height: 10%;
  }
  .community_postIndex {
    width: 5%;
    font-size: 17px;
    display: flex;
  }
  .community_postTitle {
    font-size: 17px;
    width: 70%;
    white-space: nowrap;
    overflow-x: scroll;
  }

  .community_postAuthor {
    font-size: 17px;
    width: 10%;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .community_postCreatedAt {
    width: 13%;
    font-size: 17px;
    margin-left: 20px;
    display: flex;
  }
  .community_postReadCount {
    width: 3%;
    display: flex;
    margin-left: 30px;
    font-size: 17px;
  }
  #categorybox_container {
    margin-left: 15px;
    font-size: 17px;
  }
  .categorybox_container_header_search {
    font-size: 17px;
    margin-bottom: 10px;
  }
  .category_search_input {
    height: 25px;
    margin-top: 20px;
  }
  .categorybox_container_tag {
    width: 80%;
    margin-left: 15px;
    font-size: 17px;
  }
  #community_not_login {
    display: flex;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 220px;
    height: 60px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 35px;
    height: 35px;
  }
  .not_login_boardTxt {
    font-size: 22px;
    margin-top: 15px;
    margin-left: 18px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 16px;
    border-bottom: 1px solid #ff22ff;
  }
}
@media screen and (max-width: 1440px) {
  #community_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
    border: solid 4px #00ee13;
  }
  .community_uploadRecentIcon {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .community_uploadRecentIcon {
    width: 7px;
    height: 7px;
    border-radius: 10px;
    margin-left: 3px;
    margin-right: 3px;
  }
  #community_profileContainer {
    width: 15vw;
    top: -25px;
    height: 40px;
    right: 30px;
    border: solid 4px #00ee13;
  }
  .community_profileMenuOptions {
    font-size: 16px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 95%;
    height: 60vh;
    margin-top: 40px;
    justify-content: flex-start;
    /* margin-left: 5px; */
  }

  #myinfo_togglemenu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 252px;
    height: 72px;
    bottom: -30px;
    left: 80px;
    border: solid 4px #00ee13;
  }
  #community_side_detail {
    width: 13%;
    height: 50%;
    display: flex;
    right: -4.3vw;
    bottom: 150px;
  }
  .pagination {
    display: flex;
    width: 80vw;
    padding-left: 5vw;
    /* font-family: "Alfredo"; */
    font-size: 13px;
    font-weight: 600;
  }
  .pagination > li {
    margin: 0 10px;
  }
  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 16px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor_btn {
    font-size: 16px;
  }
  /* #community_writeBtn {
    width: 70px;
    height: 25px;
    position: absolute;
    bottom: 10.3vh;
    right: 80px;
    font-size: 16px;
  } */
  .community_postIndex {
    display: flex;
    width: 5%;
    font-size: 16px;
  }
  .community_postTitle {
    font-size: 16px;
    width: 63%;
  }

  .community_postAuthor {
    font-size: 16px;
    width: 12%;
  }
  .community_postCreatedAt {
    width: 15%;
    font-size: 16px;
    margin-left: 5px;
    display: flex;
  }
  .community_postReadCount {
    display: flex;
    width: 7%;
    margin-left: 5px;

    font-size: 16px;
  }
  #categorybox_container {
    margin-left: 17px;
    font-size: 16px;
  }
  .categorybox_container_header_search {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .category_search_input {
    height: 20px;
    margin-top: 10px;
  }
  .categorybox_container_tag {
    width: 80%;
    margin-left: 5px;
    font-size: 16px;
  }
  #community_not_login {
    display: flex;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 30px;
    height: 30px;
  }
  .not_login_boardTxt {
    font-size: 16px;
    margin-top: 12px;
    margin-left: 15px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 16px;
    border-bottom: 1px solid #ff22ff;
  }
}
@media screen and (max-width: 1024px) {
  #community_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 60vh;
    margin-left: 100px;
    margin-top: 150px;
    border: 4px solid #00ee13;
  }
  .community_uploadRecentIcon {
    width: 0px;
    height: 0px;
    border-radius: 10px;
    margin-left: 0px;
    margin-right: 5px;
  }
  #community_profileContainer {
    width: 30vw;
    top: -25px;
    height: 40px;
    right: 30px;
    border: solid 3px #00ee13;
    display: none;
  }
  .community_profileMenuOptions {
    font-size: 14px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 95%;
    height: 45vh;
    margin-top: 20px;
    justify-content: flex-start;
    /* margin-left: 5px; */
  }

  #myinfo_togglemenu {
    display: none;
  }
  #community_side_detail {
    display: none;
  }
  .pagination {
    display: flex;
    width: 80vw;
    /* font-family: "Alfredo"; */
    font-size: 11px;
    font-weight: 600;
    padding-left: 40px;
  }
  .pagination > li {
    margin: 0 10px;
  }
  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 15px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor {
    width: 450px;
  }
  .pagination_kor_btn {
    border: 2px solid #00ee13;
    padding: 3px 5px;
  }
  .pagination_kor_btn {
    font-size: 15px;
  }
  .kor_list {
    margin-left: 10px;
    padding: 6px 0px;
  }
  /* #community_writeBtn {
    width: 60px;
    height: 20px;
    position: absolute;
    bottom: 8vh;
    right: 55px;
    font-size: 15px;
  } */
  .community_postIndex {
    display: none;
    width: 0%;
    font-size: 10px;
  }
  .community_postTitle {
    font-size: 15px;
    width: 79%;
  }

  .community_postAuthor {
    font-size: 15px;
    width: 18%;
    margin-left: 10px;
  }
  .community_postCreatedAt {
    width: 0%;
    font-size: 10px;
    margin-left: 0px;
    display: none;
  }
  .community_postReadCount {
    display: none;
    font-size: 10px;
  }
  #community_not_login {
    display: none;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 30px;
    height: 30px;
  }
  .not_login_boardTxt {
    font-size: 16px;
    margin-top: 12px;
    margin-left: 15px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 12px;
    border-bottom: 1px solid #ff22ff;
  }
  #mobileInfoBox {
    width: 220px;
    height: 48px;
    background-color: #1a1a1a;
    border: 2px solid #00ee13;
    bottom: -15px;
    left: 50px;
  }
  #mobileInfoLeftBox {
    width: 120px;
    height: 100%;
  }
  #mobileInfoLeftImg {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  #mobileInfoLeftContent {
    width: 90px;
    height: 20px;
    font-size: 15px;
    padding-top: 3px;
    padding-left: 10px;
  }
  #mobileInfoRightBox {
    width: 35px;
    height: 35px;
  }
}

@media screen and (max-width: 768px) {
  #community_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 100px;
    margin-top: 140px;
    border: 4px solid #00ee13;
  }

  #community_profileContainer {
    width: 30vw;
    top: -25px;
    height: 35px;
    right: 30px;
    border: solid 4px #00ee13;
    display: none;
  }
  .community_profileMenuOptions {
    font-size: 13px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 95%;
    height: 60vh;
    margin-top: 10px;
    justify-content: flex-start;
    /* margin-left: 5px; */
  }

  #myinfo_togglemenu {
    display: none;
  }
  #community_side_detail {
    display: none;
  }
  .pagination {
    display: flex;
    width: 80vw;
    /* font-family: "Alfredo"; */
    font-size: 11px;
    font-weight: 600;
    padding-left: 40px;
  }
  .pagination > li {
    margin: 0 8px;
    color: #00ee13;
  }
  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 14px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor_btn {
    font-size: 14px;
  }
  /* #community_writeBtn {
    width: 60px;
    height: 20px;
    position: absolute;
    bottom: 55px;
    right: 45px;
    font-size: 14px;
  } */
  .community_postIndex {
    display: none;
    width: 10%;
    font-size: 10px;
  }
  .community_postTitle {
    font-size: 14px;
    width: 75%;
  }
  .community_uploadRecentIcon {
  }
  .community_postAuthor {
    font-size: 14px;
    width: 20%;
  }
  .community_postCreatedAt {
    width: 20%;
    font-size: 10px;
    margin-left: 20px;
    display: none;
  }
  .community_postReadCount {
    display: none;
    font-size: 10px;
  }
  #community_not_login {
    display: none;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 30px;
    height: 30px;
  }
  .not_login_boardTxt {
    font-size: 16px;
    margin-top: 12px;
    margin-left: 15px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 15px;
    border-bottom: 0.4px solid #ff22ff;
  }
  #mobileInfoBox {
    width: 170px;
    height: 35px;
    background-color: #1a1a1a;
    border: 2px solid #00ee13;
    bottom: -15px;
    left: 40px;
  }
  #mobileInfoLeftBox {
    width: 130px;
    height: 100%;
  }
  #mobileInfoLeftImg {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  #mobileInfoLeftContent {
    width: 80px;
    height: 20px;
    font-size: 14px;
    padding-top: 4px;
    padding-left: 10px;
  }
  #mobileInfoRightBox {
    width: 30px;
    height: 30px;
  }
}

@media screen and (max-width: 425px) {
  #community_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 70px;
    border: 4px solid #00ee13;
  }
  .mobile_counter {
    color: #ff02ff;
    font-weight: normal;
    font-size: 12px;
    margin-left: 2px;
  }
  .community_uploadRecentIcon {
    width: 0px;
    height: 0px;
    border-radius: 10px;
    margin: 0;
  }
  #community_profileContainer {
    width: 30vw;
    top: -25px;
    height: 35px;
    right: 30px;
    border: solid 3px #00ee13;
    display: none;
  }
  .community_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 100%;
    height: 60vh;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
  #community_side_detail {
    display: none;
  }
  .pagination {
    width: 80vw;
    color: #00ee13;
    display: flex;
    padding-left: 10px;
    /* font-family: "Alfredo"; */
    font-size: 10px;
    font-weight: 600;
  }
  .pagination > li {
    margin: 0 5px;
    color: #00ee13;
  }
  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 13px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor {
    width: 150px;
    margin-left: 11px;
  }
  .pagination_kor_btn {
    border: 2px solid #00ee13;
    padding: 1px 7px;
    font-size: 11px;
    margin: 0;
  }
  .kor_list {
    width: 61px;
    margin-right: 13px;
    margin-left: 13px;
    padding: 2.5px 1px;
    font-size: 13px;
  }
  /* #community_writeBtn {
    width: 40px;
    height: 15px;
    position: absolute;
    bottom: 35px;
    right: 30px;
    font-size: 13px;
  } */
  .community_content_box {
    min-height: 10%;
    height: auto;
  }
  .community_postIndex {
    display: none;
    /* width: 10%;
    font-size: 8px; */
  }
  .community_postTitle {
    font-size: 13px;
    width: 80%;
    line-height: 1.1rem;
    white-space: normal;
  }

  .community_postAuthor {
    font-size: 13px;
    width: 20%;
    white-space: normal;
    line-height: 1.1rem;
    /* margin-left: 50px; */
  }
  .community_postCreatedAt {
    /* width: 20%;
    font-size: 7px;
    margin-left: 8px; */
    display: none;
  }
  .community_postReadCount {
    display: none;
  }
  #community_not_login {
    display: none;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 25px;
    height: 25px;
  }
  .not_login_boardTxt {
    font-size: 12px;
    margin-top: 8px;
    margin-left: 6px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 9px;
    border-bottom: 0.4px solid #ff22ff;
  }
  #mobileInfoBox {
    width: 140px;
    height: 30px;
    background-color: #1a1a1a;
    border: 2px solid #00ee13;
    bottom: -15px;
    left: 5px;
  }
  #mobileInfoLeftBox {
    width: 90px;
    height: 100%;
  }
  #mobileInfoLeftImg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
  #mobileInfoLeftContent {
    width: 60px;
    height: 20px;
    font-size: 13px;
    padding-top: 3px;
    padding-left: 5px;
  }
  #mobileInfoRightBox {
    width: 30px;
    height: 20px;
  }
}

@media screen and (max-width: 375px) {
  #community_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 65px;
    border: 4px solid #00ee13;
  }
  .mobile_counter {
    font-size: 11px;
  }
  #community_profileContainer {
    width: 30vw;
    top: -25px;
    height: 35px;
    right: 30px;
    border: solid 3px #00ee13;
    display: none;
  }
  .community_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #community_detail_Container {
    width: 100%;
    height: 60vh;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
  #community_side_detail {
    display: none;
  }
  .pagination {
    width: 80vw;
    display: flex;
    font-size: 10px;
    font-weight: 600;
    padding-left: 10px;
  }

  .pagination > li {
    margin: 0 5px;
    color: #00ee13;
  }

  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 12px;
    color: #00ee13;
  }

  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor {
    width: 150px;
    margin-left: 7px;
    margin-bottom: 2px;
  }
  .pagination_kor_btn {
    border: 2px solid #00ee13;
    padding: 1px 7px;
    font-size: 10px;
    margin: 0;
  }
  .kor_list {
    width: 61px;
    margin-right: 13px;
    margin-left: 12px;
    padding: 2.5px 0px;
    font-size: 12px;
  }
  /* #community_writeBtn {
    width: 35px;
    height: 15px;
    position: absolute;
    bottom: 28px;
    right: 28px;
    font-size: 12px;
  } */
  .community_postIndex {
    display: none;
    /* width: 10%;
    font-size: 8px; */
  }
  .community_postTitle {
    font-size: 12px;
    width: 80%;
  }
  .community_uploadRecentIcon {
  }
  .community_postAuthor {
    font-size: 12px;
    width: 20%;
    /* margin-left: 50px; */
  }
  .community_postCreatedAt {
    /* width: 20%;
    font-size: 6px;
    margin-left: 8px; */
    display: none;
  }
  .community_postReadCount {
    display: none;
  }
  #community_not_login {
    display: none;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 25px;
    height: 25px;
  }
  .not_login_boardTxt {
    font-size: 12px;
    margin-top: 8px;
    margin-left: 6px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 9px;
    border-bottom: 0.4px solid #ff22ff;
  }
  #mobileInfoBox {
    width: 130px;
    height: 30px;
    background-color: #1a1a1a;
    border: 2px solid #00ee13;
    bottom: -15px;
    left: 5px;
  }
  #mobileInfoLeftBox {
    width: 80px;
    height: 100%;
  }
  #mobileInfoLeftImg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
  #mobileInfoLeftContent {
    width: 60px;
    height: 20px;
    font-size: 12px;
    padding-top: 4px;
    padding-left: 5px;
  }
  #mobileInfoRightBox {
    width: 30px;
    height: 20px;
  }
}

@media screen and (max-width: 320px) {
  #community_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 55px;
    border: 4px solid #00ee13;
  }
  .mobile_counter {
    font-size: 10px;
  }
  #community_profileContainer {
    width: 30vw;
    top: -25px;
    height: 35px;
    right: 30px;
    border: solid 3px #00ee13;
    display: none;
  }
  .community_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }

  #community_detail_Container {
    width: 100%;
    height: 60vh;
    margin-top: 10px;
    justify-content: flex-start;
    margin-left: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
  #community_side_detail {
    display: none;
  }
  .pagination {
    width: 80vw;
    display: flex;
    padding-left: 10px;
    /* font-family: "Alfredo"; */
    font-size: 10px;
    font-weight: 600;
  }
  .pagination > li {
    margin: 0 5px;
    color: #00ee13;
  }
  .pagination_link_class {
    /* font-family: "Alfredo"; */
    font-size: 11px;
    color: #00ee13;
  }
  .active {
    border-bottom: 3px solid #ff02ff;
  }
  .pagination_kor {
    width: 130px;
    margin-bottom: 2px;
  }
  .pagination_kor_btn {
    border: 2px solid #00ee13;
    padding: 1px 6px;
    font-size: 9px;
  }
  .kor_list {
    width: 61px;
    margin-right: 13px;
    margin-left: 12px;
    padding: 1.5px 0px;
    font-size: 11px;
  }
  /* #community_writeBtn {
    width: 35px;
    height: 15px;
    position: absolute;
    bottom: 21px;
    right: 22px;
    font-size: 11px;
  } */
  .community_content_box {
    height: 10%;
  }
  .community_postIndex {
    display: none;
    /* width: 10%;
    font-size: 8px; */
  }
  .community_postTitle {
    font-size: 11px;
    width: 80%;
    line-height: 1rem;
    white-space: nowrap;
    overflow-x: auto;
  }
  .community_uploadRecentIcon {
  }
  .community_postAuthor {
    font-size: 11px;
    width: 20%;
    margin-left: 10px;
    line-height: 1rem;
    white-space: nowrap;
    overflow-x: auto;
  }
  .community_postCreatedAt {
    display: none;
    /* width: 20%;
    font-size: 6px;
    margin-left: 8px; */
  }
  .community_postReadCount {
    display: none;
  }
  #community_not_login {
    display: none;
  }
  .not_login_boardlower {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    height: 50px;
    bottom: -20px;
    left: -20px;
    border: solid 3px #00ee13;
  }
  .not_login_boardImg {
    width: 25px;
    height: 25px;
  }
  .not_login_boardTxt {
    font-size: 12px;
    margin-top: 8px;
    margin-left: 6px;
  }
  .not_login_unauthorizedBox {
    margin-top: 50px;
  }
  .not_login_unauthorizedHighLight {
    font-size: 9px;
    border-bottom: 0.4px solid #ff22ff;
  }
  #mobileInfoBox {
    width: 130px;
    height: 30px;
    background-color: #1a1a1a;
    border: 2px solid #00ee13;
    bottom: -15px;
    left: 5px;
  }
  #mobileInfoLeftBox {
    width: 80px;
    height: 100%;
  }
  #mobileInfoLeftImg {
    width: 20px;
    height: 20px;
    border-radius: 20px;
  }
  #mobileInfoLeftContent {
    width: 60px;
    height: 20px;
    font-size: 11px;
    padding-top: 4px;
    padding-left: 8px;
  }
  #mobileInfoRightBox {
    width: 30px;
    height: 20px;
  }
}

@media screen and (max-width: 2800px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  #reset_title {
    top: -45vh;
    left: 0px;
    width: 100%;
    height: 10%;
    font-size: 24px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -35vh;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-bottom: 30px;
    font-size: 12px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 60%;
    height: 35px;
    margin-top: 80px;
    margin-bottom: 80px;
    padding: 0px;
    margin-left: 20px;
    font-size: 13px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1440px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 88vh;
    margin-left: 15vw;
    margin-top: 50px;
  }
  #reset_title {
    top: -45vh;
    left: 0px;
    width: 100%;
    height: 10%;
    font-size: 22px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -35vh;
  }

  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-bottom: 30px;
    font-size: 12px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 60%;
    height: 35px;
    margin-top: 60px;
    margin-bottom: 60px;
    padding: 0px;
    margin-left: 20px;
    font-size: 13px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 10vw;
    margin-top: 90px;
  }
  #reset_title {
    top: -45vh;
    left: 0px;
    width: 100%;
    height: 10%;
    font-size: 18px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -35vh;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 12px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 55px;
    margin-bottom: 55px;
    padding: 0px;
    margin-left: 20px;
    font-size: 12px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 12vw;
    margin-top: 90px;
  }
  #reset_title {
    top: -370px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 16px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -320px;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-top: 5px;
    margin-bottom: 30px;
    font-size: 12px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 55px;
    margin-bottom: 55px;
    padding: 0px;
    margin-left: 20px;
    font-size: 12px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}

@media screen and (max-width: 425px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 65px;
  }
  #reset_title {
    top: -270px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 16px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -220px;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-top: 0px;
    font-size: 12px;
    margin-bottom: 10px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 35px;
    margin-bottom: 35px;
    padding: 0px;
    margin-left: 20px;
    font-size: 12px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 35px;
    margin-top: 70px;
  }
  #reset_title {
    top: -270px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 13px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 100px;
    top: -220px;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-bottom: 10px;
    margin-top: 0px;
    font-size: 11px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0px;
    margin-left: 20px;
    font-size: 12px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 320px) {
  #reset_layout {
    position: absolute;
    top: 0px;
    width: 70vw;
    height: 70vh;
    margin-left: 45px;
    margin-top: 70px;
  }

  #reset_title {
    top: -250px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 13px;
    text-align: left;
  }
  .reset-form {
    width: 100%;
    height: 120px;
    top: -200px;
  }
  .reset_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .reset_popup {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 10px;
  }
  .reset_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .reset_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding: 0px;
    margin-left: 20px;
    font-size: 12px;
  }
  .reset_under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}

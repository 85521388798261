@media screen and (max-width: 2800px) {
  #mypf_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 60px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 18px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 65vh;
    margin-top: 80px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: block;
  }
  .mypf_listColumn {
    font-size: 11px;
  }
  .mypf_radiolabels {
    width: 40%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .mypf_tagcontents {
    height: 25px;
    width: 33%;
    font-size: 11px;
  }
  .mypf_attachContent {
    width: 70px;
    height: 30px;
    font-size: 11px;
  }
  #tag_pf_select {
    font-size: 13px;
  }
  #mypf_description {
    margin-right: 60px;
    width: 27vw;
    height: 10vh;
  }
  #mypf_descr_count {
    position: relative;
    top: -2vh;
    left: 2.5vh;
  }
  #mypf_navContainer {
    width: 80%;
    height: 35px;
    margin-top: 20px;
  }
  .myprf_navbtn {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1440px) {
  #mypf_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 50px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 15px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 65vh;
    margin-top: 80px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: block;
  }
  .mypf_listColumn {
    font-size: 10px;
  }
  .mypf_radiolabels {
    width: 40%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
  .mypf_tagcontents {
    height: 20px;
    width: 55px;
    font-size: 9px;
  }
  .mypf_attachContent {
    width: 45px;
    height: 25px;
    font-size: 9px;
  }

  #tag_pf_select {
    font-size: 11px;
  }
  #mypf_description {
    margin-right: 60px;
    width: 30vw;
    height: 10vh;
  }
  #mypf_descr_count {
    position: relative;
    top: -2vh;
    left: 1.5vh;
  }
  #mypf_navContainer {
    width: 80%;
    height: 35px;
    margin-top: 20px;
  }
  .myprf_navbtn {
    width: 60px;
    height: 25px;
    font-size: 10px;
  }
}
@media screen and (max-width: 1024px) {
  #mypf_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 60vh;
    margin-left: 100px;
    margin-top: 140px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 50vh;
    margin-top: 40px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #mypf_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 100px;
    margin-top: 130px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 60vh;
    margin-top: 40px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  #mypf_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 70px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 60vh;
    margin-top: 20px;
    justify-content: flex-start;
    margin-left: 5px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  #mypf_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 60px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 60vh;
    margin-top: 20px;
    justify-content: flex-start;
    margin-left: 5px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 10px;
  }

  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  #mypf_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 50px;
  }
  #mypf_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypf_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }

  #mypf_infoContainer {
    width: 95%;
    height: 60vh;
    margin-top: 20px;
    justify-content: flex-start;
    margin-left: 5px;
  }
  #mypf_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }

  #myinfo_togglemenu {
    display: none;
  }
}

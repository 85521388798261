@media screen and (max-width: 2800px) {
  #myprofile_introlayout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 60px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .myprofile_list {
    width: 90%;
    justify-content: space-around;
  }
  .myprofile_profileMenuOptions {
    font-size: 18px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    height: 65vh;
    margin-top: 80px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    height: 50%;
    margin-top: 30px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
    width: 100%;
    height: 100%;
    background-size: contain;
  }
  #myprofile_myname {
    font-size: 16px;
    height: 80px;
    font-weight: 700;
  }
  #myprofile_membershipstate {
    font-size: 12px;
    height: 30px;
    font-weight: 400;
  }
  #myprofile_rightbox {
    width: 50%;
    height: 70%;
    margin-left: 20px;
  }
  #myprofile_rightcontent {
    border-bottom: 1.5px solid #00ee13;
    padding-bottom: 20px;
    padding-top: 0px;
  }
  .myprofile_profilelist {
    height: 25px;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
  }
  .myprofile_profilelist_column {
    width: 4vw;
    height: 1.4vw;
    font-size: 0.6vw;
    margin-right: 5px;
  }
  .myprofile_profilelist_disabled {
    width: 200px;
    font-size: 13px;
    border-bottom: 1px solid #00ee13;
    font-weight: 500;

    line-height: 1.4rem;
  }
  .myprofile_profile_publicBox {
    display: flex;
    justify-content: space-around;
  }
  .myprofile_radioContainer {
    display: block;
  }
  .myprofile_radioForm {
  }
  .myprofile_radioSection {
  }
  .myprofile_radiolabel {
    width: 50%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
  }
  .myprofile_profilelist_input {
    width: 200px;
    height: 25px;
    margin-left: 0px;
    font-size: 13px;
    border-bottom: 1px solid #00ee13;
    line-height: 1.4rem;
  }
  .myprofile_profilelist_input_req {
    width: 200px;
    height: 25px;
    margin-left: 0px;
    font-size: 13px;
    border-bottom: 1px solid #ff02ff;
    font-weight: 500;

    line-height: 1.4rem;
  }
  #myprofile_navContainer {
    width: 80%;
    height: 35px;
    margin-top: 20px;
  }
  .myprofile_navbtn {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
  #myinfo_togglemenu {
    display: block;
  }
}
@media screen and (max-width: 1440px) {
  #myprofile_introlayout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 50px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 15px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    height: 65vh;
    margin-top: 80px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    height: 50%;
    margin-top: 30px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
  }
  #myprofile_myname {
    font-size: 16px;
    height: 40px;
  }
  #myprofile_membershipstate {
    font-size: 12px;
    height: 30px;
  }
  #myprofile_rightbox {
    width: 50%;
    height: 70%;
    margin-left: 20px;
  }
  #myprofile_rightcontent {
    border-bottom: 1.5px solid #00ee13;
    padding-bottom: 20px;
    padding-top: 0px;
  }
  .myprofile_profilelist {
    height: 25px;
    margin-top: 5px;
    align-items: center;
  }
  .myprofile_profilelist_column {
    width: 50px;
    height: 20px;
    font-size: 10px;
    margin-right: 5px;
  }
  .myprofile_profilelist_disabled {
    width: 160px;
    font-size: 11px;
    border-bottom: 1px solid #00ee13;
    line-height: 1.2rem;
  }
  .myprofile_profile_publicBox {
    /* display: flex; */
  }
  .myprofile_radioContainer {
    display: block;
  }
  .myprofile_radioForm {
  }
  .myprofile_radioSection {
  }
  .myprofile_radiolabel {
    width: 40%;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
  }
  .myprofile_profilelist_input {
    width: 160px;
    height: 25px;
    margin-left: 0px;
    font-size: 11px;
    border-bottom: 1px solid #00ee13;
    line-height: 1.2rem;
  }
  .myprofile_profilelist_input_req {
    width: 160px;
    height: 25px;
    margin-left: 0px;
    font-size: 11px;
    border-bottom: 1px solid #ff02ff;
    line-height: 1.2rem;
  }
  #myprofile_navContainer {
    width: 80%;
    height: 35px;
    margin-top: 20px;
  }
  .myprofile_navbtn {
    width: 60px;
    height: 25px;
    font-size: 10px;
  }
  #myinfo_togglemenu {
    display: block;
  }
}
@media screen and (max-width: 1024px) {
  #myprofile_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 60vh;
    margin-left: 100px;
    margin-top: 140px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    height: 50vh;
    margin-top: 40px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    height: 50%;
    margin-top: 10px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
  }
  #myprofile_myname {
    font-size: 15px;
    height: 30px;
  }
  #myprofile_membershipstate {
    font-size: 11px;
    height: 20px;
  }
  #myprofile_rightbox {
    width: 50%;
    height: 70%;
    margin-left: 20px;
  }
  #myprofile_rightcontent {
    border-bottom: 1.5px solid #00ee13;
    padding-bottom: 20px;
    padding-top: 0px;
  }
  .myprofile_profilelist {
    height: 25px;
    margin-top: 5px;
    align-items: center;
  }

  .myprofile_profilelist_column {
    width: 43px;
    height: 20px;
    font-size: 9px;
    margin-right: 5px;
  }
  .myprofile_profilelist_disabled {
    width: 35px;
    font-size: 9px;
    border: none;
    line-height: none;
    /* border: 1px solid red; */
  }
  .myprofile_profile_publicBox {
    /* display: none; */
  }
  .myprofile_radioContainer {
    display: none;
  }
  .myprofile_profilelist_input {
    width: 70px;
    height: 25px;
    margin-left: 30px;
    font-size: 9px;
    line-height: none;
    border: none;
  }
  .myprofile_profilelist_input_req {
    width: 70px;
    height: 25px;
    margin-left: 30px;
    font-size: 11px;
    line-height: none;
    border: none;
  }

  #myprofile_navContainer {
    width: 80%;
    height: 25px;
    margin-top: 20px;
  }
  .myprofile_navbtn {
    width: 70px;
    height: 23px;
    font-size: 11px;
  }
  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  #myprofile_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 100px;
    margin-top: 130px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    height: 60vh;
    margin-top: 40px;
    justify-content: flex-start;
    margin-left: 30px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    height: 50%;
    margin-top: 10px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
  }
  #myprofile_myname {
    font-size: 15px;
    height: 30px;
  }
  #myprofile_membershipstate {
    font-size: 11px;
    height: 20px;
  }
  #myprofile_rightbox {
    width: 50%;
    height: 70%;
    margin-left: 20px;
  }
  #myprofile_rightcontent {
    border-bottom: 1.5px solid #00ee13;
    padding-bottom: 20px;
    padding-top: 0px;
  }
  .myprofile_profilelist {
    height: 25px;
    margin-top: 0px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: flex-start;
  }
  .myprofile_list {
    justify-content: flex-start;
  }
  .myprofile_profilelist_column {
    width: 63px;
    height: 25px;
    font-size: 12px;
    margin-right: 5px;
  }
  .myprofile_profilelist_disabled {
    width: 200px;
    font-size: 12px;
    font-weight: 300;
    line-height: none;
    border: none;
    /* border: 1px solid red; */
  }
  .myprofile_profile_publicBox {
    display: none;
  }
  .myprofile_radioContainer {
    display: none;
  }
  .myprofile_profilelist_input {
    width: 200px;
    height: 25px;
    margin-left: 0px;
    font-size: 12px;
    font-weight: 300;
    line-height: none;
    border: none;
  }
  .myprofile_profilelist_input_req {
    width: 200px;
    height: 25px;

    margin-left: 0px;
    font-size: 12px;
    font-weight: 300;
    line-height: none;
    border: none;
  }
  .mobile_bolder {
    font-weight: bold;
  }
  #myprofile_navContainer {
    width: 80%;
    height: 25px;
    margin-top: 20px;
  }
  .myprofile_navbtn {
    width: 70px;
    height: 23px;
    font-size: 12px;
  }
  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 425px) {
  #myprofile_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 70px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    height: 65vh;
    margin-top: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    height: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    height: 70%;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
    width: 90px;
    height: 90px;
    border-radius: 90px;
    background-size: cover;
  }
  #myprofile_myname {
    font-size: 12px;
    height: 15px;
  }
  #myprofile_membershipstate {
    font-size: 9px;
    font-style: italic;
    margin-top: 5px;
    height: 15px;
  }
  #myprofile_rightbox {
    width: 80%;
    height: 70%;
    margin-left: 10px;
    /* border: 1px solid red; */
  }
  #myprofile_rightcontent {
    border: none;
    padding: 0;
  }
  .myprofile_list {
    width: 105%;
    justify-content: flex-start;
  }
  .myprofile_profilelist {
    height: 21px;
    margin-top: 0px;
    margin-bottom: 12px;
    justify-content: flex-start;
    align-items: center;
  }
  .myprofile_profilelist_column {
    width: 47px;
    height: 21px;
    font-size: 9px;
    margin-right: 5px;
  }
  .myprofile_profilelist_disabled {
    width: 150px;
    font-weight: 300;
    font-size: 11px;
    border: none;
    line-height: none;
    /* border: 1px solid red; */
  }
  .myprofile_profile_publicBox {
    display: none;
    /* border: 1px solid red; */
  }
  .myprofile_radioContainer {
    display: none;
  }
  .myprofile_profilelist_input {
    width: 150px;
    height: 21px;
    font-size: 11px;
    font-weight: 300;

    margin-left: 0px;
    border: none;
    line-height: none;
    /* border: 1px solid red; */
  }
  .myprofile_profilelist_input_req {
    width: 150px;
    height: 21px;
    font-size: 11px;
    font-weight: 300;
    margin-left: 0px;
    border: none;
    line-height: none;
  }
  .mobile_bolder {
    font-weight: bold;
  }
  #myprofile_navContainer {
    width: 80%;
    height: 25px;
    margin-top: 10px;
    margin-bottom: 35px;
  }
  .myprofile_navbtn {
    width: 66px;
    height: 24px;
    font-size: 11px;
  }
  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  #myprofile_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 60px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    /* height: 60vh; */
    margin-top: 20px;
    /* justify-content: flex-start; */
    margin-left: 5px;
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 10px;
  }
  #myprofile_imageClickBox {
    width: 100%;
    /* height: 50%; */
    /* margin-top: 0px; */
    margin-bottom: 5px;
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
  }
  #myprofile_myname {
    font-size: 11px;
    /* height: 15px; */
  }
  #myprofile_membershipstate {
    /* font-size: 6px; */
    /* height: 15px; */
  }
  #myprofile_rightbox {
    width: 90%;
    /* width: 50%; */
    height: 70%;
    margin-left: 10px;
    /* border: 1px solid red; */
  }
  #myprofile_rightcontent {
    /* border-bottom: 1.5px solid #00ee13; */
    /* padding-bottom: 20px; */
    /* padding-top: 20px; */
  }
  .myprofile_profilelist {
    /* height: 25px; */
    /* margin-top: 0px; */
    /* border: 1px solid red; */
    /* align-items: center; */
  }
  .myprofile_profilelist_column {
    /* width: 25px; */
    /* height: 13px; */
    /* font-size: 4.5px; */
    /* margin-right: 5px; */
  }
  .myprofile_profilelist_disabled {
    /* width: 25px; */
    /* font-size: 5.5px; */
    border: none;
    line-height: none;
  }
  .myprofile_profile_publicBox {
    /* border: 1px solid red; */
  }
  .myprofile_radioContainer {
    display: none;
  }
  .myprofile_profilelist_input {
    /* width: 30px; */
    /* height: 25px; */
    /* margin-left: 0px; */
    /* font-size: 6px; */
    /* line-height: none; */
    /* border: none; */
  }
  .myprofile_profilelist_input_req {
    /* width: 30px; */
    /* height: 25px; */
    /* margin-left: 0px; */
    /* font-size: 6px; */
    /* line-height: none; */
    /* border: none; */
  }
  #myprofile_navContainer {
    width: 80%;
    /* height: 15px; */
    /* margin-top: 10px; */
    /* border: 1px solid orange; */
  }
  .myprofile_navbtn {
    /* width: 30px; */
    /* height: 15px; */
    /* font-size: 6px; */
  }
  #myinfo_togglemenu {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  #myprofile_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 50px;
  }
  #myprofile_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .myprofile_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myprofile_infoDetail {
    /* background-color: red; */
  }
  #myprofile_infoContainer {
    width: 95%;
    /* height: 60vh; */
    /* margin-top: 20px; */
    /* justify-content: flex-start; */
    /* margin-left: 5px; */
  }
  #myprofile_infoLeftBox {
    width: 40%;
    margin-top: 0px;
  }
  #myprofile_imageClickBox {
    /* width: 100%; */
    margin-bottom: 10px;
    /* height: 50%; */
    /* margin-top: 15px; */
  }
  #myprofile_imageClickLabel {
  }
  #myprofile_image {
    width: 70px;
    height: 70px;
    border-radius: 70px;
  }
  #myprofile_myname {
    /* font-size: 8px; */
    /* height: 15px; */
  }
  #myprofile_membershipstate {
    /* font-size: 6px; */
    /* height: 15px; */
  }
  #myprofile_rightbox {
    width: 100%;
    /* width: 50%; */
    /* height: 80%; */
    /* margin-left: 10px; */
    /* border: 1px solid red; */
  }
  #myprofile_rightcontent {
    /* border-bottom: 1.5px solid #00ee13; */
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
  }
  .myprofile_profilelist {
    /* height: 25px; */
    /* margin-top: 0px; */
    /* border: 1px solid red; */
    /* align-items: center; */
  }
  .myprofile_profilelist_column {
    /* width: 25px; */
    /* height: 13px; */
    /* font-size: 4.5px; */
    /* margin-right: 5px; */
  }
  .myprofile_profilelist_disabled {
    /* width: 25px; */
    /* font-size: 4.5px; */
    /* border: none; */
    /* line-height: none; */
  }
  .myprofile_profile_publicBox {
    /* border: 1px solid red; */
  }
  .myprofile_radioContainer {
    display: none;
  }
  .myprofile_profilelist_input {
    /* width: 25px;
    height: 25px;
    margin-left: 0px;
    font-size: 4px;
    line-height: none; */
  }
  .myprofile_profilelist_input_req {
    /* width: 25px;
    height: 25px;
    margin-left: 0px;
    font-size: 4px;
    line-height: none;
    border: none; */
  }
  #myprofile_navContainer {
    /* width: 80%; */
    /* height: 15px; */
    /* margin-top: 10px; */
    /* border: 1px solid orange; */
  }
  .myprofile_navbtn {
    /* width: 30px; */
    /* height: 15px; */
    /* font-size: 6px; */
  }
  #myinfo_togglemenu {
    display: none;
  }
}

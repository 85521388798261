@media screen and (max-width: 2800px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }

  #join_title {
    top: -85px;
    left: 40px;
    width: 100%;
    height: 10%;
    font-size: 30px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 20vh;
    top: -120px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 15px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 60%;
    height: 45px;
    margin-top: 30px;
    margin-bottom: 55px;
    margin-left: 17vw;
    font-size: 13px;
  }
  .under_nav_box {
    padding: 0px 30px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1440px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 88vh;
    margin-left: 15vw;
    margin-top: 50px;
  }

  #join_title {
    top: -85px;
    left: 40px;
    width: 100%;
    height: 10%;
    font-size: 30px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 20vh;
    top: -120px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 13px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 40px;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 9vw;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 1024px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 10vw;
    margin-top: 90px;
  }

  #join_title {
    top: 65px;
    left: 20px;
    width: 100%;
    height: 10%;
    font-size: 25px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 20vh;
    top: -120px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 13px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 9vw;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 12vw;
    margin-top: 90px;
  }

  #join_title {
    top: -40px;
    left: 15px;
    width: 100%;
    height: 10%;
    font-size: 20px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 250px;
    top: -80px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 13px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 35px;
    margin-top: 10px;
    margin-bottom: 25px;
    margin-left: 65px;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}

@media screen and (max-width: 425px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 65px;
  }

  #join_title {
    top: -13px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 16px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 150px;
    top: -80px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 35px;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 375px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 35px;
    margin-top: 70px;
  }

  #join_title {
    top: -13px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 16px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 150px;
    top: -80px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 20px;
    margin-left: 33px;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}
@media screen and (max-width: 320px) {
  #join_layout {
    position: absolute;
    top: 0px;
    width: 70vw;
    height: 70vh;
    margin-left: 45px;
    margin-top: 70px;
  }

  #join_title {
    top: -13px;
    left: 10px;
    width: 100%;
    height: 10%;
    font-size: 13px;
    text-align: left;
  }
  .join-form {
    width: 100%;
    height: 100px;
    top: -80px;
  }
  .join_form_item {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .join_icons {
    font-size: 12px;
    margin-right: 5px;
  }
  .join_submit_btn {
    width: 80%;
    height: 30px;
    margin-top: 5px;
    margin-bottom: 15px;
    margin-left: 20px;
    font-size: 12px;
  }
  .under_nav_box {
    padding: 0px 10px;
    font-size: 11px;
  }
}

/* 폰트사이즈 전부 반응형 */
/* 스타일컴포넌트에서 적용된건 오버라이드 안되는 듯 */
/* 사이즈 작은 순으로 css 읽어야함*/
/* .myframe {
  width: 90%;
  height: 60vh;
  margin-bottom: 20px;
} */
@media screen and (max-width: 2800px) {
  #header_scroll {
    top: 100px;
    left: 40px;
    height: 80vh;
    border: solid 4px #00ee13;
  }
  .myframe {
    width: 60%;
    height: 50vh;
    margin-bottom: 20px;
  }
  .portfolio_header_left_img {
    width: 50%;
    height: 80%;
  }
  .portfolio_detail_headerbox {
    justify-content: space-between;
    align-items: center;
    height: 35vh;
  }
  .portfolio_hedaer_right {
    width: 40%;
    height: 80%;
  }
  .TagBox {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 40%;
  }
  .TagContents {
    font-weight: 600;
    font-size: 17px;
    height: 20%;
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 3px;
    font-size: 17px;
  }
  .TagSpan_PF_Header {
    padding: 4px 3px;
    font-size: 17px;
  }
  .CompanyName {
    height: 15%;
    font-weight: 600;
    margin-top: 10px;
    font-size: 17px;
  }
  .Oneline {
    height: 15%;
    font-weight: 400;
    font-size: 17px;
  }
  .WebLink {
    height: 15%;
    font-weight: 400;
    font-size: 17px;
  }
  .Email {
    height: 15%;
    font-weight: 400;
    font-size: 17px;
  }

  .Business {
    height: 15%;
    font-weight: 400;
    font-size: 17px;
  }

  .ContentHeader {
    width: 85px;
    /* height: 30px; */
    font-weight: 600;
    margin-bottom: 20px;
    font-size: 17px;
  }

  .ContentFullDes {
    width: 85%;
    height: 30vh;
    font-weight: 400;
    font-size: 17px;
    line-height: 1.3rem;
  }
  #pf_detail_header {
    padding-top: 10px;
    margin-right: 13.8vw;
    font-size: 28px;
  }
  #pf_detail_nav_btn {
    bottom: 12vh;
    right: 8vw;
  }
  .pf_detail_new_btn_box {
    width: 90%;
    margin-top: 60px;
    height: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #pf_detail_nav_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    width: 150px;
    height: 28%;
    border: 2px solid #00ee13;
  }
}
@media screen and (max-width: 1920px) {
  .myframe {
    width: 70%;
  }
  .portfolio_header_left_img {
    width: 50%;
    height: 85%;
  }
}
@media screen and (max-width: 1820px) {
  .myframe {
    width: 900px;
    height: 60vh;
  }
}
@media screen and (max-width: 1440px) {
  #header_scroll {
    top: 100px;
    left: 40px;
    height: 80vh;
    border: solid 4px #00ee13;
  }
  .myframe {
    width: 85%;
    height: 55vh;
    margin-bottom: 20px;
  }
  .TagBox {
    height: 40%;
  }
  .TagContents {
    font-weight: 600;
    font-size: 16px;
    height: 20%;
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 3px;
    font-size: 16px;
  }
  .TagSpan_PF_Header {
    padding: 5px 3px;
    font-size: 16px;
  }
  .CompanyName {
    font-weight: 600;
    margin-top: 10px;
    font-size: 16px;
  }
  .Oneline {
    font-weight: 400;
    font-size: 16px;
  }
  .WebLink {
    font-weight: 400;
    font-size: 16px;
  }
  .Email {
    font-weight: 400;
    font-size: 16px;
  }

  .Business {
    font-weight: 400;
    font-size: 16px;
  }

  .ContentHeader {
    width: 85px;
    height: 30px;
    font-weight: 600;
    font-size: 16px;
  }

  .ContentFullDes {
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3rem;
  }
  #pf_detail_header {
    padding-top: 10px;
    margin-right: 13.5vw;
    font-size: 26px;
  }
  .pf_detail_new_btn_box {
    margin-top: 60px;
    height: 110px;
  }
  #pf_detail_nav_btn {
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* width: 150px; */
    height: 32%;
    font-size: 16px;
    border: 2px solid #00ee13;
  }
}
@media screen and (max-width: 1366px) {
  .myframe {
    width: 93%;
    height: 68vh;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1280px) {
  .myframe {
    height: 60vh;
  }
}
@media screen and (max-width: 1024px) {
  #header_scroll {
    top: 155px;
    left: 0px;
    height: 65vh;
    border: solid 4px #00ee13;
  }
  .portfolio_header_left_img {
    width: 50%;
    height: 50%;
  }
  .myframe {
    height: 400px;
    margin-bottom: 20px;
  }
  .TagBox {
    height: 40%;
  }
  .TagContents {
    font-weight: 600;
    font-size: 15px;
    height: 20%;
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 3px;
    font-size: 15px;
  }
  .TagSpan_PF_Header {
    padding: 5px 3px;
    font-size: 15px;
  }
  .CompanyName {
    font-weight: 600;
    margin-top: 0px;
    font-size: 15px;
  }
  .Oneline {
    font-weight: 400;
    font-size: 15px;
  }
  .WebLink {
    font-weight: 400;
    font-size: 15px;
  }
  .Email {
    font-weight: 400;
    font-size: 15px;
  }

  .Business {
    font-weight: 400;
    font-size: 15px;
  }

  .ContentHeader {
    width: 80px;
    height: 30px;
    font-weight: 600;
    font-size: 15px;
  }

  .ContentFullDes {
    font-weight: 400;
    font-size: 15px;
    line-height: 1.3rem;
  }
  #pf_detail_header {
    padding-top: 12px;
    margin-right: 12vw;
    font-size: 24px;
  }
  .pf_detail_new_btn_box {
    margin-top: 60px;
    height: 110px;
  }
  #pf_detail_nav_btn {
    font-size: 16px;
    border: 2px solid #00ee13;
  }
}

@media screen and (max-width: 768px) {
  #header_scroll {
    top: 150px;
    left: 0px;
    height: 78vh;
    border: solid 4px #00ee13;
  }
  .myframe {
    height: 300px;
  }
  .TagBox {
    height: 40%;
  }
  .TagContents {
    font-weight: 600;
    font-size: 14px;
    height: 30%;
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 3px;
    font-size: 14px;
  }
  .TagSpan_PF_Header {
    padding: 5px 3px;
    font-size: 14px;
  }
  .CompanyName {
    font-weight: 600;
    font-size: 14px;
  }
  .Oneline {
    font-weight: 400;
    font-size: 14px;
  }
  .WebLink {
    font-weight: 400;
    font-size: 14px;
  }
  .Email {
    font-weight: 400;
    font-size: 14px;
  }

  .Business {
    font-weight: 400;
    font-size: 14px;
  }

  .ContentHeader {
    width: 16%;
    height: 100%;
    /* font-weight: 600; */
    /* font-size: 14px; */
  }

  .ContentFullDes {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.3rem;
  }
  #pf_detail_header {
    padding-top: 10px;
    margin-right: 97px;
    font-size: 22px;
  }
  #pf_detail_nav_btn {
    bottom: 6vh;
    right: 15vw;
  }
}

@media screen and (max-width: 425px) {
  #header_scroll {
    top: 60px;
    left: 0px;
    height: 78vh;
    border: solid 4px #00ee13;
  }
  #pf_detail_header {
    margin-left: 20px;
    margin-bottom: 15px;
  }
  .portfolio_detail_headerbox {
    flex-direction: column;
    justify-content: flex-start;
    height: 370px;
  }
  .portfolio_header_left_img {
    width: 90%;
    height: 140px;
    margin-bottom: 20px;
  }
  .portfolio_hedaer_right {
    width: 90%;
    height: 220px;
  }
  .TagBox {
    width: 100%;
    height: 10%;
    flex-direction: row;
    justify-content: flex-start;
    /* align-items: flex-start; */
    /* justify-content: space-between; */
  }

  .TagContents {
    font-weight: 600;
    font-size: 13px;
    height: 50%;
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 5px;
    margin-right: 8px;
    font-size: 11px;
  }
  .TagSpan_PF_Header {
    padding: 4px 3px;
    font-size: 11px;
  }
  .CompanyName {
    height: 15%;
    font-weight: bold;
    margin-top: 0;
    font-size: 12px;
  }
  .Oneline {
    height: 15%;
    font-weight: 300;
    font-size: 12px;
  }
  .WebLink {
    height: 15%;
    font-weight: 300;
    font-size: 12px;
  }
  .Email {
    height: 15%;
    font-weight: 300;
    font-size: 12px;
  }

  .Business {
    height: 15%;
    font-weight: 300;
    font-size: 12px;
  }

  .ContentHeader {
    width: 28%;
    margin-left: 14px;
    height: 100%;
    /* font-weight: 600; */
    /* font-size: 13px; */
  }
  .myframe {
    width: 90%;
    height: 10%;
    margin-left: 15px;
  }
  .ContentFullDes {
    width: 90%;
    margin-left: 15px;
    height: auto;
    font-weight: 300;
    font-size: 13px;
    line-height: 1.1rem;
  }
  #pf_detail_header {
    padding-top: 10px;
    margin-right: 50px;
    font-size: 20px;
  }
  .pf_detail_new_btn_box {
    width: 90%;
    margin-top: 15px;
    height: 60px;
    display: flex;
    justify-content: center;
  }
  #pf_detail_nav_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    width: 100px;
    height: 50%;
    border: 2px solid #00ee13;
  }
}

@media screen and (max-width: 375px) {
  #header_scroll {
    top: 55px;
    left: 0px;
    height: 78vh;
    border: solid 4px #00ee13;
  }
  .myframe {
    height: 140px;
    margin-left: 13px;
  }
  .TagBox {
    /* height: 40%; */
  }
  .TagContents {
    font-weight: 600;
    font-size: 12px;
    /* height: 30%; */
    margin-bottom: 3px;
  }

  .TagSpan {
    /* padding: 3px; */
    /* font-size: 11px; */
  }
  .TagSpan_PF_Header {
    /* padding: 4px 3px; */
    font-size: 11px;
  }
  .CompanyName {
    /* font-weight: 600; */
    /* margin-top: 0px; */
    /* font-size: 12px; */
  }
  .Oneline {
    /* font-weight: 400; */
    /* font-size: 12px; */
  }
  .WebLink {
    /* font-weight: 400; */
    /* font-size: 12px; */
  }
  .Email {
    /* font-weight: 400; */
    /* font-size: 12px; */
  }

  .Business {
    /* font-weight: 400; */
    /* font-size: 12px; */
  }

  .ContentHeader {
    /* width: 30%; */
    /* height: 100%; */
    /* font-weight: 600; */
    /* font-size: 2vw; */
    /* margin-left: 13px; */
  }

  .ContentFullDes {
    /* font-weight: 500; */
    font-size: 12px;
    /* line-height: 1.1rem; */
  }
  #pf_detail_header {
    /* padding-top: 10px; */
    margin-right: 45px;
    font-size: 17px;
  }
  #pf_detail_nav_btn {
    /* bottom: 10vh; */
    /* right: 15vw; */
  }
}

@media screen and (max-width: 320px) {
  #header_scroll {
    top: 50px;
    left: 0px;
    height: 75vh;
    border: solid 4px #00ee13;
  }
  .TagBox {
    /* height: 40%; */
  }
  .TagContents {
    /* font-weight: 600; */
    font-size: 11px;
    /* height: 30%; */
    margin-bottom: 3px;
  }
  .TagSpan {
    padding: 3px;
    font-size: 11px;
  }
  .TagSpan_PF_Header {
    padding: 4px 3px;
    font-size: 11px;
  }
  .CompanyName {
    /* font-weight: 600; */
    /* margin-top: 0px; */
    font-size: 11px;
  }
  .Oneline {
    /* font-weight: 400; */
    font-size: 11px;
  }
  .WebLink {
    /* font-weight: 400; */
    font-size: 11px;
  }
  .Email {
    /* font-weight: 400; */
    font-size: 11px;
  }

  .Business {
    /* font-weight: 400; */
    font-size: 11px;
  }
  .myframe {
    height: 120px;
    margin-left: 10px;
  }
  .ContentHeader {
    /* width: 30%; */
    /* width: auto; */
    /* height: 100%; */
    /* font-weight: 600; */
    /* font-size: 2vw; */
    margin-left: 9px;
  }

  .ContentFullDes {
    /* font-weight: 500; */
    font-size: 11px;
    /* line-height: 1.1rem; */
  }
  #pf_detail_header {
    /* padding-top: 10px; */
    margin-right: 37px;
    font-size: 15px;
  }
  #pf_detail_nav_btn {
    /* bottom: 15vh; */
    /* right: 15vw; */
    font-size: 11px;
  }
}

.ant-input {
  width: 50vw;
  height: 30px;
  /* width: 200px; */
  /* height: 30px; */
  background-color: transparent;
  color: #00ee13;
  outline: none;
  font-size: 12.5px;
}
.ant-popover {
  position: absolute;
}
.ant-popover-title {
  color: white;
  font-size: 15px;
  font-family: "Noto Sans KR";
  font-weight: 600;
  letter-spacing: 0.1rem;
  margin-bottom: 15px;
  opacity: 0.7;
}

.ant-btn {
  height: 100%;
  background-color: #1a1a1a;
  /* height: 40px; */
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 600;
  /* margin-top: 20px; */
  /* margin-bottom: 45px; */
  color: #00ee13;
  border: 1px solid #00ee13;
}
.ant-btn:hover {
  background-color: #00ee13;
  color: #1a1a1a;
  cursor: grab;
}
.ant-col > a {
  font-family: "Noto Sans KR";
  /* font-size: 13px; */
  font-weight: 600;
  /* margin-bottom: 24px; */
}
.ant-col-lg-30 {
  /* width: 450px; */
  display: flex;
  justify-content: space-between;
}
.ant-col-lg-45 {
  margin-right: 45px;
}
.ant-col-lg-45 > ul > li {
  margin-bottom: 15px;
}
.full-width > h2 {
  font-family: "Noto Sans KR";
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.full-width > p {
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 400;
  color: white;
  margin-bottom: 10px;
}
.ant-col-lg-12 {
  margin-top: 30px;
}

.ant-col-lg-44 {
  margin-top: 350px;
  z-index: 1;
}

.ant-col-lg-55 {
  margin-top: 350px;
  z-index: 1;
}
.ant-col-lg-66 {
  margin-top: 260px;
  z-index: 1;
}
.text-center {
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 25px;
}
.ant-notification-notice-content {
  position: absolute;
  bottom: 500px;
  left: 850px;
  font-family: "Noto Sans KR";
  font-size: 13px;
  font-weight: 500;
}
.ant-notification-close-icon {
  display: none;
}
.ant-notification-notice-icon-success {
  display: none;
}
.ant-notification-notice-icon-error {
  display: none;
}
.ant-notification-notice-description {
  margin-top: 15px;
}
.ant-notification-notice-with-icon > i {
  display: none;
}
.anticon-spin {
  color: #00ee13;
}

button {
  outline: none;
}

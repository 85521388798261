@media screen and (max-width: 2800px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  #user_header {
    font-size: 26px;
  }
  .userContainer {
    justify-content: space-around;
    align-items: center;
    height: 80%;
  }
  .userLeftBox {
    width: 30%;
    height: 80%;
  }
  .userRightBox {
    width: 50%;
    height: 80%;
  }
  .userdetail_flexbox {
    width: 100%;
    margin-top: 20px;
    justify-content: center;
  }
  #userdetail_left_portfolioInfo {
    font-size: 13px;
    width: 85px;
    height: 30px;
  }
  #userdetail_left_portfoliostate {
    font-size: 13px;
    height: 30px;
    width: 60px;
    margin-left: 5px;
    justify-content: center;
    font-weight: 600;
  }
  .userDetailImg {
    width: 100%;
    height: 50%;
    background-size: contain;
    margin-bottom: 20px;
  }
  .userRightList {
    width: 90%;
    justify-content: space-around;
    margin-bottom: 20px;
  }
  .userdetail_userRightColumn {
    margin: 0 auto;
    width: 85px;
    height: 30px;
    font-size: 13px;
  }
  .userdetail_userRightInput {
    width: 22vw;
    height: 30px;
    font-size: 13px;
    padding-top: 7px;
    padding-left: 0px;
    font-weight: 600;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_inputbox {
    width: 22vw;
    height: 30px;
    font-size: 13px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_userRightSpan {
    height: 30px;
    font-size: 13px;
    padding-top: 0px;
    padding-left: 0px;
    font-weight: 600;
    overflow-x: scroll;
    white-space: nowrap;
  }

  .userdetail_underbackbtn {
    width: 85px;
    height: 35px;
    font-size: 13px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 1440px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  #user_header {
    font-size: 26px;
  }
  #userdetail_left_portfolioInfo {
    font-size: 12px;
    width: 80px;
    height: 30px;
  }
  #userdetail_left_portfoliostate {
    font-size: 12px;
    height: 30px;
    width: 60px;
    margin-left: 5px;
  }
  .userDetailImg {
    width: 100%;
    height: 40%;
  }
  .userRightList {
    margin-bottom: 15px;
  }
  .userdetail_userRightColumn {
    width: 80px;
    height: 30px;
    font-size: 12px;
  }
  .userdetail_userRightInput {
    width: 22vw;
    height: 30px;
    font-size: 12px;
    padding-top: 7px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_inputbox {
    width: 22vw;
    height: 30px;
    font-size: 12px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_userRightSpan {
    height: 30px;
    font-size: 12px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_underbackbtn {
    width: 85px;
    height: 35px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
  }
  #user_header {
    font-size: 23px;
  }
  #userdetail_left_portfolioInfo {
    font-size: 10px;
    width: 65px;
    height: 25px;
  }
  #userdetail_left_portfoliostate {
    font-size: 10px;
    height: 25px;
    width: 45px;
    margin-left: 5px;
  }
  .userDetailImg {
    width: 100%;
    height: 40%;
  }
  .userRightList {
    margin-bottom: 15px;
  }
  .userdetail_userRightColumn {
    width: 60px;
    height: 25px;
    font-size: 10px;
  }
  .userdetail_userRightInput {
    width: 20vw;
    height: 25px;
    font-size: 10px;
    padding-top: 7px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_inputbox {
    width: 20vw;
    height: 25px;
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_userRightSpan {
    height: 25px;
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_underbackbtn {
    width: 80px;
    height: 30px;
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
  }
  #user_header {
    font-size: 23px;
  }
  #userdetail_left_portfolioInfo {
    font-size: 10px;
    width: 65px;
    height: 25px;
  }
  #userdetail_left_portfoliostate {
    font-size: 10px;
    height: 25px;
    width: 45px;
    margin-left: 5px;
  }
  .userDetailImg {
    width: 100%;
    height: 40%;
  }
  .userRightList {
    margin-bottom: 15px;
  }
  .userdetail_userRightColumn {
    width: 60px;
    height: 25px;
    font-size: 10px;
  }
  .userdetail_userRightInput {
    width: 110px;
    height: 25px;
    font-size: 10px;
    padding-top: 7px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_inputbox {
    width: 110px;
    height: 25px;
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_userRightSpan {
    height: 25px;
    font-size: 10px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .userdetail_underbackbtn {
    width: 80px;
    height: 30px;
    font-size: 11px;
  }
}

@media screen and (max-width: 425px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  .userContainer {
    flex-direction: column;
  }

  .userRightBox {
    width: 250px;
    height: 250px;
  }
  #user_header {
    font-size: 15px;
  }
  .userDetailImg {
    width: 112px;
    height: 112px;
    background-size: cover;
    border-radius: 112px;
    margin-bottom: 0px;
  }
  .userdetail_flexbox {
    margin: 0;
    margin-left: 30px;
    justify-content: flex-start;
  }
  #userdetail_left_portfolioInfo {
    font-size: 9px;
    width: 47px;
    height: 21px;
  }
  #userdetail_left_portfoliostate {
    font-size: 12px;
    height: 25px;
    width: 150px;
    justify-content: flex-start;
    margin-left: 5px;
  }

  .userRightList {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 10px;
  }
  .userdetail_userRightColumn {
    margin: 0;
    margin-left: 30px;
    width: 47px;
    height: 21px;
    font-size: 9px;
  }
  .userdetail_userRightInput {
    width: 150px;
    height: auto;
    font-weight: 300;
    font-size: 11px;
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 5px;
    white-space: normal;
    word-break: break-all;
    line-height: 1.1rem;
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
  }
  .userdetail_realname {
    font-weight: bold;
  }
  .userdetail_nickname {
    font-weight: bold;
  }
  .userdetail_inputbox {
    width: 150px;
    height: auto;
    font-size: 11px;
    padding-top: 0px;
    padding-left: 0px;
    margin-left: 5px;
    white-space: normal;
    word-break: break-all;
    line-height: 1.1rem;
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
  }
  .userdetail_userRightSpan {
    width: 100%;
    height: auto;
    font-size: 11px;
    padding-top: 0px;
    padding-left: 0px;
    font-weight: 300;
    white-space: normal;
    word-break: break-all;
    line-height: 1.1rem;
  }
  .userdetail_underbackbtn {
    width: 66px;
    height: 24px;
    font-size: 11px;
    margin: 0 auto;
    margin-top: 10px;
  }
}
@media screen and (max-width: 375px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  #user_header {
    font-size: 15px;
  }
  #userdetail_left_portfolioInfo {
    font-size: 9px;
    /* width: 55px; */
    /* height: 25px; */
  }
  #userdetail_left_portfoliostate {
    font-size: 11px;
    /* height: 25px; */
    /* width: 35px; */
    /* margin-left: 5px; */
  }

  .userRightList {
    /* margin-bottom: 5px; */
  }
  .userdetail_userRightColumn {
    /* width: 40px; */
    /* height: 20px; */
    font-size: 9px;
  }
  .userdetail_userRightInput {
    /* width: 60px; */
    /* height: 20px; */
    /* font-size: 9px; */
    /* padding-top: 5px; */
    /* padding-left: 0px; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
  }
  .userdetail_inputbox {
    /* width: 60px;
    height: 20px;
    font-size: 9px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap; */
  }
  .userdetail_userRightSpan {
    /* height: 20px;
    font-size: 9px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap; */
  }
  .userdetail_underbackbtn {
    /* width: 50px; */
    /* height: 25px; */
    font-size: 11px;
  }
}
@media screen and (max-width: 320px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  #user_header {
    font-size: 15px;
  }
  #userdetail_left_portfolioInfo {
    /* font-size: 6px; */
    /* width: 45px; */
    /* height: 25px; */
  }
  #userdetail_left_portfoliostate {
    /* font-size: 6px; */
    /* height: 25px; */
    /* width: 30px; */
    /* margin-left: 5px; */
  }
  .userDetailImg {
    /* width: 100%; */
    /* height: 40%; */
  }
  .userRightList {
    /* margin-bottom: 5px; */
  }
  .userdetail_userRightColumn {
    /* width: 40px; */
    /* height: 20px; */
    /* font-size: 8px; */
  }
  .userdetail_userRightInput {
    /* width: 60px;
    height: 20px;
    font-size: 9px;
    padding-top: 5px;
    padding-left: 5px;
    overflow-x: scroll;
    white-space: nowrap; */
  }
  .userdetail_inputbox {
    /* width: 60px;
    height: 20px;
    font-size: 9px;
    padding-top: 0px;
    padding-left: 5px;
    overflow-x: scroll;
    white-space: nowrap; */
  }
  .userdetail_userRightSpan {
    /* height: 20px;
    font-size: 9px;
    padding-top: 0px;
    padding-left: 0px;
    overflow-x: scroll;
    white-space: nowrap; */
  }
  .userdetail_underbackbtn {
    /* width: 50px; */
    /* height: 25px; */
    font-size: 11px;
  }
}

@media screen and (max-width: 2800px) {
  #mypost_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 60px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 18px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: block;
  }
  #mypost_header {
    font-size: 22px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 6%;
    height: 35px;
    font-size: 13px;
  }
  .mypost_title {
    width: 40%;
    height: 35px;
    font-size: 13px;
  }
  .mypost_detailbtn {
    width: 6%;
    height: 35px;
    font-size: 13px;
  }
}
@media screen and (max-width: 1440px) {
  #mypost_layout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 50px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 15px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: block;
  }
  #mypost_header {
    font-size: 18px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 12%;
    height: 25px;
    font-size: 11px;
  }
  .mypost_title {
    width: 30%;
    height: 25px;
    font-size: 11px;
  }
  .mypost_detailbtn {
    width: 12%;
    height: 25px;
    font-size: 11px;
  }
}
@media screen and (max-width: 1024px) {
  #mypost_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 60vh;
    margin-left: 100px;
    margin-top: 170px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #mypost_header {
    font-size: 16px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 12%;
    height: 25px;
    font-size: 11px;
  }
  .mypost_title {
    width: 30%;
    height: 25px;
    font-size: 11px;
  }
  .mypost_detailbtn {
    width: 12%;
    height: 25px;
    font-size: 11px;
  }
}

@media screen and (max-width: 768px) {
  #mypost_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 100px;
    margin-top: 160px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #mypost_header {
    font-size: 16px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 15%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_title {
    width: 30%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_detailbtn {
    width: 15%;
    height: 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 425px) {
  #mypost_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 70px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #mypost_header {
    font-size: 16px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 25%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_title {
    width: 30%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_detailbtn {
    width: 20%;
    height: 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 375px) {
  #mypost_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 60px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #mypost_header {
    font-size: 16px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 25%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_title {
    width: 40%;
    height: 20px;
    font-size: 9px;
  }
  .mypost_detailbtn {
    width: 20%;
    height: 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 320px) {
  #mypost_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 50px;
  }
  #mypost_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .mypost_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #mypost_header {
    font-size: 16px;
    margin-top: 20px;
  }
  .mypost_column {
    width: 25%;
    height: 20px;
    font-size: 10px;
  }
  .mypost_title {
    width: 40%;
    height: 20px;
    font-size: 9px;
  }
  .mypost_detailbtn {
    width: 20%;
    height: 20px;
    font-size: 10px;
  }
}

@media screen and (max-width: 2800px) {
  #question_introlayout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 60px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 18px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 22px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 28px;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .myq_desc {
    height: 40%;
    font-size: 13px;
    margin-bottom: 10px;
  }
  .myq_btn {
    width: 100px;
    height: 30px;
    font-size: 12px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 20px;
  }
  .myq_holdingbox {
    width: 30%;
    height: 30%;
    margin-left: 27vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 13px;
    padding-right: 35px;
    padding-top: 20px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1440px) {
  #question_introlayout {
    position: absolute;
    top: 100px;
    width: 80vw;
    height: 80vh;
    margin-left: 200px;
    margin-top: 0px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 50px;
    right: 15px;
    border: solid 4px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 15px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 20px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 25px;
    font-size: 11px;
    margin-bottom: 10px;
  }
  .myq_desc {
    height: 40%;
    font-size: 11px;
    margin-bottom: 10px;
  }
  .myq_btn {
    width: 80px;
    height: 30px;
    font-size: 11px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 8vh;
  }
  .myq_holdingbox {
    width: 25%;
    height: 25%;
    margin-left: 28vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 12px;
    padding-right: 35px;
    padding-top: 20px;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1024px) {
  #question_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 60vh;
    margin-left: 100px;
    margin-top: 170px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 20px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 18px;
    font-size: 9px;
    margin-bottom: 10px;
  }
  .myq_desc {
    height: 40%;
    font-size: 9px;
    margin-bottom: 10px;
  }
  .myq_btn {
    width: 60px;
    height: 25px;
    font-size: 9px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 8vh;
  }
  .myq_holdingbox {
    width: 23%;
    height: 23%;
    margin-left: 28vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 11px;
    padding-top: 10px;
    margin-top: 20px;
    padding-right: 40px;
  }
}
@media screen and (max-width: 768px) {
  #question_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 100px;
    margin-top: 160px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 4px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 12px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 20px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 23px;
    font-size: 10px;
    margin-bottom: 10px;
  }
  .myq_desc {
    height: 40%;
    font-size: 10px;
    margin-bottom: 10px;
  }
  .myq_btn {
    width: 60px;
    height: 25px;
    font-size: 9px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 10vh;
  }
  .myq_holdingbox {
    width: 25%;
    height: 25%;
    margin-left: 27vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 11px;
    padding-top: 30px;
    margin-top: 20px;
    padding-right: 35px;
  }
}
@media screen and (max-width: 425px) {
  #question_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 70px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 17px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 17px;
    font-size: 8px;
    margin-bottom: 3px;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .myq_desc {
    height: 50%;
    font-size: 8px;
    margin-bottom: 3px;
  }
  .myq_btn {
    width: 45px;
    height: 20px;
    font-size: 8px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 10vh;
  }
  .myq_holdingbox {
    width: 20%;
    height: 20%;
    margin-left: 26vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 8px;
    padding-top: 0px;
    margin-top: 10px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 375px) {
  #question_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 60px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 17px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 17px;
    font-size: 8px;
    margin-bottom: 3px;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .myq_desc {
    height: 50%;
    font-size: 8px;
    margin-bottom: 3px;
  }
  .myq_btn {
    width: 45px;
    height: 20px;
    font-size: 8px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 10vh;
  }
  .myq_holdingbox {
    width: 20%;
    height: 20%;
    margin-left: 25vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 8px;
    padding-top: 0px;
    margin-top: 10px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 320px) {
  #question_introlayout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 70vh;
    margin-left: 40px;
    margin-top: 50px;
  }
  #question_profileContainer {
    width: 60vw;
    top: -30px;
    height: 40px;
    right: 5px;
    border: solid 3px #00ee13;
  }
  .question_profileMenuOptions {
    font-size: 10px;
    margin-right: 0px;
  }
  #myinfo_togglemenu {
    display: none;
  }
  #question_container {
  }
  #question_myqbox {
    margin-top: 20px;
    margin-left: 20px;
  }
  .myq_header {
    font-size: 17px;
    margin-bottom: 3px;
  }
  .myq_title {
    height: 17px;
    font-size: 8px;
    margin-bottom: 3px;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .myq_desc {
    height: 50%;
    font-size: 8px;
    margin-bottom: 3px;
  }
  .myq_btn {
    width: 45px;
    height: 20px;
    font-size: 8px;
  }
  #question_myabox {
    margin-left: 20px;
    margin-top: 10vh;
  }
  .myq_holdingbox {
    width: 20%;
    height: 20%;
    margin-left: 25vw;
  }
  .myq_holdingtext {
    width: 100%;
    height: 30px;
    font-size: 8px;
    padding-top: 0px;
    margin-top: 10px;
    padding-right: 35px;
  }
}

@media screen and (max-width: 2800px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
  }
  .logoBox {
    height: 30vh;
  }
  .headerLogo {
    width: 210px;
    height: 210px;
    margin-bottom: 5px;
    margin-left: 0px;
    background-size: contain;
  }
}
@media screen and (max-width: 1440px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
  }
  .logoBox {
    height: 30vh;
  }
  .headerLogo {
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    margin-left: 3px;
    background-size: contain;
  }
}
@media screen and (max-width: 1024px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
    z-index: 5;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
    padding-top: 55px;
    padding-left: 40px;
  }
  .headerLogo {
    width: 150px;
    height: 150px;
    background-size: cover;
  }
}

@media screen and (max-width: 768px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
    z-index: 5;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
    padding-top: 55px;
    padding-left: 40px;
  }
  .headerLogo {
    width: 150px;
    height: 150px;
    background-size: cover;
  }
}

@media screen and (max-width: 425px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
    z-index: 5;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 0px;
  }
  .headerLogo {
    width: 100px;
    height: 100px;
    background-size: cover;
  }
}

@media screen and (max-width: 375px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
    z-index: 5;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
    padding-top: 10px;
    padding-left: 8px;
  }
  .headerLogo {
    width: 90px;
    height: 90px;
    background-size: cover;
  }
}

@media screen and (max-width: 320px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
    z-index: 5;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
    padding-top: 15px;
    padding-left: 15px;
  }
  .headerLogo {
    width: 70px;
    height: 70px;
    background-size: cover;
  }
}

@media screen and (max-width: 2800px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  .emailconfirmWrapper {
    margin-left: 17vw;
  }
  .confirm-txt {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 80%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1440px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 80vw;
    height: 88vh;
    margin-left: 15vw;
    margin-top: 50px;
  }
  .emailconfirmWrapper {
    margin-left: 15vw;
  }
  .confirm-txt {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 100%;
    height: 35px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 15px;
  }
}
@media screen and (max-width: 1024px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 10vw;
    margin-top: 90px;
  }
  .emailconfirmWrapper {
    margin-left: 17vw;
  }
  .confirm-txt {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 12vw;
    margin-top: 90px;
  }
  .emailconfirmWrapper {
    margin-left: 130px;
  }
  .confirm-txt {
    color: white;
    font-size: 13px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 425px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 65px;
  }
  .emailconfirmWrapper {
    margin-left: 45px;
  }
  .confirm-txt {
    color: white;
    font-size: 10px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 12px;
  }
}
@media screen and (max-width: 375px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 35px;
    margin-top: 70px;
  }
  .emailconfirmWrapper {
    margin-left: 30px;
  }
  .confirm-txt {
    color: white;
    font-size: 10px;
    margin-bottom: 10px;
  }

  .confirm_submit_btn {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 12px;
  }
}
@media screen and (max-width: 320px) {
  #confirm_layout {
    position: absolute;
    top: 0px;
    width: 70vw;
    height: 70vh;
    margin-left: 45px;
    margin-top: 70px;
  }
  .emailconfirmWrapper {
    margin-left: 5px;
  }
  .confirm-txt {
    color: white;
    font-size: 8px;
    margin-bottom: 10px;
  }
  .confirm_submit_btn {
    width: 100%;
    height: 30px;
    margin-top: 15px;
    margin-bottom: 25px;
    padding: 0px 5px 0px 5px;
    margin-left: 0px;
    font-size: 12px;
  }
}

@media screen and (max-width: 2800px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  .post_upload_container {
    width: 100%;
  }
  .post_upload_box {
    width: 85%;
    height: 70%;
  }
  .post_upload_list {
    height: 10%;
  }
  .post_upload_column {
    width: 87px;
    height: 29px;
    font-size: 14px;
  }
  .up_post_upload_category {
    width: 290px;
    height: 29px;
    font-size: 17px;
  }
  .post_upload_category {
    width: 280px;
    height: 29px;
    font-size: 17px;
  }
  .post_upload_categroy_circle {
    margin-left: 10px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    width: 280px;
    height: 29px;
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
    margin-left: 10px;
  }
  .post_upload_wrtier_img {
    width: 35px;
    height: 29px;
    border-radius: 30px;
  }
  .post_upload_writer_nickname {
    width: 130px;
    height: 29px;
    font-size: 17px;
    margin-left: 10px;
  }
  .post_upload_link {
    width: 305px;
    height: 29px;
    font-size: 17px;
    margin-left: 10px;
  }
  .post_upload_column_second {
    width: 87px;
    height: 29px;
    font-size: 14px;
  }
  .post_upload_field {
    width: 280px;
    height: 29px;
    margin-left: 15px;
    margin-right: 10px;
    font-size: 17px;
  }
  .post_upload_cost {
    width: 250px;
    height: 29px;
    margin-left: 15px;
    margin-right: 16px;
    font-size: 17px;
  }
  .post_upload_title {
    width: 1060px;
    height: 29px;
    margin-left: 15px;
    font-size: 17px;
  }
  .post_upload_description {
    margin-left: 15px;
    width: 1060px;
    height: 35vh;
    font-size: 17px;
  }
  .post_btn_box {
    width: 400px;
    position: absolute;
    bottom: 50px;
    left: 620px;
    /* margin-right: 10vw; */
  }
  .post_btn {
    width: 118px;
    height: 39px;
    font-size: 17px;
    margin: 0 30px;
    /* margin: 0 30px; */
  }
  .post_upload_fileBox {
    width: 240px;
    height: 29px;
  }
  .post_upload_attachContent {
    width: 87px;
    height: 29px;
    /* margin-left: 0px; */
    font-size: 13px;
  }
  .post_upload_attachSpan {
    display: block;
    font-size: 17px;
    padding-top: 6px;
    margin-left: 10px;
    width: 130px;
    height: 29px;
  }
}
@media screen and (max-width: 1920px) {
  .post_btn_box {
    left: 580px;
  }
}
@media screen and (max-width: 1600px) {
  .post_upload_title {
    width: 990px;
  }
  .post_upload_description {
    width: 990px;
  }
  .post_upload_link {
    width: 235px;
  }
  .post_btn_box {
    left: 490px;
  }
}
@media screen and (max-width: 1440px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  .post_upload_box {
    width: 90%;
    height: 85%;
  }
  .post_upload_column {
    /* width: 87px; */
    /* height: 29px; */
    /* font-size: 14px; */
  }
  .post_upload_category {
    width: 280px;
    height: 29px;
    font-size: 16px;
  }
  .post_upload_categroy_circle {
    margin-left: 10px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    width: 200px;
    height: 29px;
    /* font-size: 11px; */
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 35px;
    height: 29px;
    border-radius: 30px;
  }
  .post_upload_writer_nickname {
    /* width: 35px; */
    /* height: 25px; */
    font-size: 16px;
  }
  .post_upload_link {
    width: 270px;
    /* width: 18.5vw; */
    /* height: 25px; */
    font-size: 16px;
    /* margin-left: 10px; */
  }
  .post_upload_column_second {
    /* width: 60px; */
    /* height: 25px; */
    /* font-size: 15px; */
  }
  .post_upload_field {
    width: 285px;
    /* width: 220px; */
    /* height: 25px; */
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
  }
  .post_upload_cost {
    width: 175px;
    /* width: 200px; */
    /* height: 25px; */
    margin-left: 10px;
    font-size: 16px;
  }
  .post_upload_title {
    width: 948px;
    /* width: 50vw; */
    /* height: 25px; */
    margin-left: 10px;
    font-size: 16px;
  }
  .post_upload_description {
    margin-left: 10px;
    width: 948px;
    height: 37vh;
    font-size: 16px;
  }
  .post_btn_box {
    left: 450px;
    /* width: 80%; */
  }
  .post_btn {
    /* width: 80px; */
    /* height: 35px; */
    font-size: 16px;
  }
  .post_upload_fileBox {
    /* width: 200px; */
    /* height: 25px; */
  }
  .post_upload_attachContent {
    /* width: 60px; */
    /* height: 25px; */
    font-size: 16px;
  }
  .post_upload_attachSpan {
    display: block;
    font-size: 16px;
    /* padding-top: 6px; */
    /* padding-left: 10px; */
    /* width: 100px; */
  }
}
@media screen and (max-width: 1366px) {
  .post_upload_field {
    width: 283px;
  }
  .post_upload_cost {
    width: 170px;
  }
  .post_btn_box {
    left: 410px;
  }
}
@media screen and (max-width: 1280px) {
  .post_upload_link {
    width: 180px;
  }
  .post_upload_field {
    width: 285px;
  }
  .post_upload_cost {
    width: 175px;
  }
  .post_upload_title {
    width: 865px;
  }
  .post_upload_description {
    width: 865px;
  }
  .post_btn_box {
    left: 380px;
  }
}
@media screen and (max-width: 1024px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
  }
  .post_upload_column {
    width: 67px;
    height: 25px;
    /* font-size: 14px; */
  }
  .up_post_upload_category {
    width: 190px;
    height: 29px;
    font-size: 15px;
  }
  .post_upload_category {
    width: 180px;
    height: 25px;
    font-size: 15px;
  }
  .post_upload_categroy_circle {
    margin-left: 10px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    /* width: 100px; */
    /* height: 20px; */
    /* font-size: 10px; */
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 35px;
    height: 25px;
    border-radius: 15px;
  }
  .post_upload_writer_nickname {
    width: 130px;
    height: 25px;
    font-size: 15px;
  }
  .post_upload_link {
    width: 155px;
    /* width: 10vw; */
    height: 25px;
    font-size: 15px;
    margin-left: 5px;
  }
  .post_upload_column_second {
    width: 67px;
    height: 25px;
    /* font-size: 9px; */
  }
  .post_upload_field {
    width: 194px;
    height: 25px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 15px;
  }
  .post_upload_cost {
    width: 190px;
    height: 25px;
    margin-left: 3px;
    margin-right: 8px;
    font-size: 15px;
  }
  .post_upload_title {
    width: 695px;
    height: 25px;
    margin-left: 5px;
    font-size: 15px;
  }
  .post_upload_description {
    margin-left: 5px;
    width: 695px;
    /* height: 30vh; */
    font-size: 15px;
  }
  .post_btn_box {
    /* width: 80%; */
    width: 300px;
    left: 310px;
  }
  .post_btn {
    /* width: 60px; */
    /* height: 25px; */
    /* font-size: 9px; */
    font-size: 15px;
    margin: 0 15px;
  }
  .post_upload_fileBox {
    width: 220px;
    height: 25px;
  }
  .post_upload_attachContent {
    width: 67px;
    height: 25px;
    font-size: 14px;
  }
  .post_upload_attachSpan {
    display: block;
    font-size: 15px;
    padding-top: 6px;
    padding-left: 5px;
    width: 125px;
  }
}
@media screen and (max-width: 970px) {
  .post_btn_box {
    left: 270px;
  }
}
@media screen and (max-width: 800px) {
  .post_upload_column {
    width: 55px;
  }
  .post_upload_category {
    width: 120px;
  }
  .up_post_upload_category {
    width: 130px;
    font-size: 17px;
  }
  .post_upload_column_second {
    width: 55px;
  }
  .post_upload_link {
    width: 80px;
  }
  .post_upload_field {
    width: 135px;
  }
  .post_upload_cost {
    width: 190px;
  }
  .post_upload_fileBox {
    width: 150px;
  }
  .post_upload_attachSpan {
    width: 80px;
    font-size: 11px;
  }
  .post_upload_title {
    width: 540px;
  }
  .post_upload_description {
    width: 540px;
  }
  .post_btn_box {
    width: 280px;
    left: 215px;
    bottom: 30px;
  }
  .post_btn {
    margin: 0 10px;
  }
}
@media screen and (max-width: 768px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
  }
  .post_upload_column {
    /* width: 50px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .up_post_upload_category {
    font-size: 14px;
  }
  .post_upload_category {
    /* width: 80px; */
    /* height: 20px; */
    font-size: 14px;
  }
  .post_upload_categroy_circle {
    margin-left: 10px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    /* width: 100px; */
    /* height: 20px; */
    /* font-size: 10px; */
    width: 165px;
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 35px;
    height: 25px;
    border-radius: 15px;
  }
  .post_upload_writer_nickname {
    width: 130px;
    height: 25px;
    font-size: 14px;
  }
  .post_upload_link {
    width: 100px;
    /* height: 20px; */
    /* font-size: 10px; */
    /* margin-left: 10px; */
  }
  .post_upload_column_second {
    /* width: 50px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .post_upload_field {
    width: 135px;
    /* height: 20px; */
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
  }
  .post_upload_cost {
    width: 150px;
    /* height: 20px; */
    margin-left: 5px;
    font-size: 14px;
  }
  .post_upload_title {
    width: 520px;
    height: 25px;
    margin-left: 5px;
    font-size: 14px;
  }
  .post_upload_description {
    margin-left: 5px;
    width: 520px;
    height: 400px;
    font-size: 14px;
  }
  .post_btn_box {
    /* width: 80%; */
    left: 197px;
  }
  .post_btn {
    /* width: 60px; */
    /* height: 25px; */
    /* font-size: 9px; */
  }
  .post_upload_fileBox {
    width: 160px;
    height: 25px;
  }
  .post_upload_attachContent {
    width: 55px;
    height: 25px;
    font-size: 11px;
  }
  .post_upload_attachSpan {
    display: block;
    font-size: 12px;
    padding-top: 5px;
    padding-left: 5px;
    width: 85px;
  }
}

@media screen and (max-width: 425px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  .post_upload_box {
    width: 95%;
    height: 90%;
  }
  .post_upload_list {
    height: 7%;
  }
  .post_upload_column {
    width: 45px;
    height: 23px;
    font-size: 11px;
  }
  .post_upload_category {
    width: 80px;
    height: 23px;
    font-size: 13px;
  }
  .up_post_upload_category {
    width: 95px;
    font-size: 13px;
  }
  .post_upload_categroy_circle {
    margin-left: 5px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    display: none;
    width: 50px;
    height: 20px;
    font-size: 11px;
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 15px;
    height: 20px;
    border-radius: 15px;
  }
  .post_upload_writer_nickname {
    width: 15px;
    height: 20px;
    font-size: 8px;
  }
  .post_upload_link {
    width: 115px;
    height: 23px;
    font-size: 12px;
    margin-left: 5px;
  }
  .post_upload_column_second {
    width: 45px;
    height: 23px;
    font-size: 11px;
  }
  .post_upload_field {
    width: 97px;
    height: 23px;
    margin-left: 3px;
    margin-right: 5px;
    font-size: 13px;
  }
  .post_upload_cost {
    width: 115px;
    height: 23px;
    /* margin-left: 3px; */
    font-size: 13px;
  }
  .post_upload_title {
    width: 265px;
    height: 23px;
    margin-left: 5px;
    font-size: 13px;
  }
  .post_upload_description {
    margin-left: 5px;
    width: 265px;
    height: 300px;
    font-size: 13px;
  }
  .post_btn_box {
    /* width: 80%; */
    left: 93px;
  }
  .post_btn {
    width: 64px;
    height: 24px;
    font-size: 11px;
  }
  .post_upload_fileBox {
    width: 150px;
    height: 23px;
  }
  .post_upload_attachContent {
    width: 45px;
    height: 23px;
    font-size: 10px;
  }
  .post_upload_attachSpan {
    margin: 0;
    font-size: 12px;
  }
}

@media screen and (max-width: 375px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  .post_upload_column {
    /* width: 45px; */
    /* height: 23px; */
    /* font-size: 11px; */
  }
  .post_upload_category {
    /* width: 55px; */
    /* height: 20px; */
    font-size: 12px;
  }
  .up_post_upload_category {
    font-size: 12px;
  }
  .post_upload_categroy_circle {
    margin-left: 5px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    display: none;
    width: 50px;
    height: 20px;
    font-size: 11px;
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 15px;
    height: 20px;
    border-radius: 15px;
  }
  .post_upload_writer_nickname {
    width: 15px;
    height: 20px;
    font-size: 8px;
  }
  .post_upload_link {
    width: 88px;
    height: 23px;
    font-size: 12px;
    margin-left: 5px;
  }
  .post_upload_column_second {
    width: 45px;
    height: 23px;
    font-size: 11px;
  }
  .post_upload_field {
    width: 95px;
    height: 23px;
    margin-left: 5px;
    margin-right: 5px;
    font-size: 12px;
  }
  .post_upload_cost {
    width: 87px;
    height: 23px;
    margin-left: 5px;
    font-size: 12px;
  }
  .post_upload_title {
    width: 237px;
    height: 23px;
    margin-left: 5px;
    font-size: 12px;
  }
  .post_upload_description {
    margin-left: 5px;
    width: 237px;
    height: 270px;
    font-size: 12px;
  }
  .post_btn_box {
    /* width: 80%; */
    left: 82px;
  }
  .post_btn {
    /* width: 45px; */
    /* height: 20px; */
    /* font-size: 8px; */
  }
  .post_upload_fileBox {
    /* width: 36px; */
    /* height: 20px; */
  }
  .post_upload_attachContent {
    /* width: 36px; */
    /* height: 20px; */
    /* font-size: 6px; */
  }
  .post_upload_attachSpan {
    /* display: none; */
  }
}

@media screen and (max-width: 320px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 25px;
    margin-top: 30px;
  }
  .post_upload_column {
    /* width: 40px; */
    /* height: 20px; */
    /* font-size: 11px; */
  }
  .post_upload_category {
    width: 60px;
    /* height: 20px; */
    font-size: 11px;
  }
  .up_post_upload_category {
    width: 75px;
    font-size: 11px;
  }
  .post_upload_categroy_circle {
    margin-left: 5px;
  }
  .post_upload_cateogry_inner {
    padding-left: 5px;
    padding-top: 5px;
  }
  .post_upload_wrtier {
    display: none;
    width: 50px;
    height: 20px;
    font-size: 11px;
  }
  .post_upload_writer_content {
    overflow-x: scroll;
    white-space: nowrap;
  }
  .post_upload_wrtier_img {
    width: 15px;
    height: 20px;
    border-radius: 15px;
  }
  .post_upload_writer_nickname {
    width: 15px;
    height: 20px;
    font-size: 8px;
  }
  .post_upload_link {
    width: 62px;
    height: 23px;
    font-size: 11px;
    margin-left: 5px;
  }
  .post_upload_column_second {
    /* width: 30px; */
    /* height: 20px; */
    font-size: 11px;
  }
  .post_upload_field {
    width: 75px;
    height: 23px;
    /* margin-left: 3px; */
    margin-right: 5px;
    font-size: 11px;
  }
  .post_upload_cost {
    width: 61px;
    height: 23px;
    /* margin-left: 3px; */
    font-size: 11px;
  }
  .post_upload_title {
    width: 190px;
    height: 23px;
    margin-left: 5px;
    font-size: 11px;
  }
  .post_upload_description {
    margin-left: 5px;
    width: 190px;
    height: 230px;
    font-size: 11px;
  }
  .post_btn_box {
    width: 80%;
    left: 57px;
  }
  .post_btn {
    /* width: 45px; */
    /* height: 20px; */
    /* font-size: 11px; */
  }
  .post_upload_fileBox {
    /* width: 30px; */
    /* height: 20px; */
  }
  .post_upload_attachContent {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 11px; */
  }
  .post_upload_attachSpan {
    /* display: none; */
  }
}

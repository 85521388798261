@media screen and (max-width: 2800px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
    /* 레이아웃 왼쪽 여백 */
    padding-left: 70px;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 15px;
    /* 위에 마진 주기 */
    margin-top: 70px;
  }
  .upload_post_list {
    width: 97%;
    /* height: 6%; */
  }
  .upload_column {
    width: 87px;
    height: 29px;
    margin-right: 16px;
    font-size: 14px;
  }
  .upload_second_left {
    margin-left: 16px;
  }
  .upload_title {
    width: 794px;
    font-size: 17px;
    height: 29px;
    margin: 0 5px;
    white-space: nowrap;
    overflow-x: scroll;
  }
  #count_comment {
    font-size: 16px;
    font-weight: bold;
    color: #ff02ff;
    width: 9px;
    height: 24px;
    margin-left: 5px;
  }
  .upload_writer {
    /* margin: 0 5px; */
    font-size: 17px;
    height: 29px;
    width: 277px;
    padding-bottom: 5px;
    font-weight: bold;
  }
  .upload_image {
    width: 24px;
    height: 24px;
    border-radius: 20px;
    border: 3px solid #00ee13;
    margin-right: 5px;
  }
  .upload_second_column {
    width: 87px;
    height: 29px;
    font-size: 14px;
    margin-right: 16px;
  }
  .upload_field {
    margin: 0 5px;
    font-size: 17px;
    height: 29px;
    width: 429px;
    overflow-x: scroll;
    white-space: nowrap;
    margin-right: 20px;
  }
  .upload_third_column {
    width: 87px;
    height: 29px;
    font-size: 14px;
    margin: 0;
    margin-right: 16px;
  }
  .upload_fourth_column {
    width: 87px;
    height: 29px;
    font-size: 14px;
    margin-right: 16px;
  }
  .upload_fifth_column {
    width: 85px;
    height: 20px;
    font-size: 17px;
    margin: 0 5px;
  }
  .upload_link {
    font-size: 17px;
    padding-bottom: 10px;
    /* margin-left: 10px; */
    height: 29px;
    border-bottom: 1px solid #00ee13;
    width: 277px;
    overflow-x: scroll;
    white-space: nowrap;
  }
  .upload_time_createdAt {
    width: 150px;
    height: 20px;
    padding-top: 0px;
    font-size: 17px;
  }
  .upload_attach_span {
    width: 794px;
    height: 29px;
    font-size: 17px;
    margin: 0 5px;
    border-bottom: 1px solid #00ee13;
  }
  .upload_quotedCost {
    margin: 0 5px;
    font-size: 17px;
    height: 29px;
    width: 236px;
    overflow-x: scroll;
    white-space: nowrap;
    margin-right: 20px;
  }
  .upload_desc_column {
    width: 87px;
    height: 29px;
    margin-right: 16px;
    font-size: 14px;
  }
  .upload_description {
    margin: 0 5px;
    width: 794px;
    height: 100%;
    font-size: 17px;
    line-height: 1.3rem;
  }
  .upload_desc_image {
    width: 100%;
    /* 이미지 크기 고정 (descript 내부) */
    height: 40vh;
    /* height: 250px; */
    margin-bottom: 30px;
  }
  #isliked_container {
    width: 1300px;
    margin-left: 15px;
    height: 8%;
  }
  #isliked_box {
    width: 150px;
    height: 100%;
  }
  #islikedCount {
    margin-right: 5px;
    width: 2vw;
    height: 20px;
    font-size: 17px;
  }
  #islikedImg {
    width: 2.5vw;
    height: 2.5vw;
  }
  #comment_list_container {
    padding-left: 0px;
    padding: 12.5px;
    margin-left: 15px;
    width: 1300px;
  }
  .comment_list {
    /* height: 30px; */
    margin-bottom: 10px;
    line-height: 1.4rem;

    /* padding-left: 3px; */
  }
  .comment_writer {
    /* width: 13vw; */
    min-width: 100px;
    width: 100px;
    font-size: 17px;
    height: auto;
  }
  .comment_content {
    width: 900px;
    /* height: 100%; */
    font-size: 17px;
    padding-left: 10px;
    word-break: break-all;
    /* white-space: pre-wrap; */
  }
  .comment_createdAt {
    width: 150px;
    /* padding-left: 11px; */
    margin-left: 12px;
    height: 30px;
    font-size: 17px;
  }
  .additionalCommentBtn {
    /* width: 10; */
    width: 51px;
    margin-left: 8px;
    margin: 0;
    font-size: 17px;
  }
  .renewal_btn_comment {
    width: 51px;
    margin-left: 8px;
    /* padding-left: 5px; */
    font-size: 17px;
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    width: 90%;
    /* height: 30px; */
    line-height: 1.4rem;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .additional_comment_writer {
    min-width: 100px;
    width: 110px;
    font-size: 17px;
    height: auto;
  }
  .additional_comment_img {
    margin-left: 11px;
    /* margin-top: 10px; */
    margin-right: 10px;
    width: 25px;
    height: 25px;
  }
  .additional_comment_content {
    /* width: 50vw; */
    width: 860px;
    font-size: 17px;
    padding-top: 3px;
    word-break: break-all;

    /* height: 30px; */
  }
  .additional_comment_createdAt {
    width: 105px;
    height: 30px;
    padding-top: 0px;
    margin: 0;
    font-size: 17px;
    padding-left: 13px;
  }
  .additional_comment_renewalbtn {
    width: 51px;
    font-size: 17px;
    margin-left: 15px;
    margin-top: 0px;
  }
  #comment_write_under_con {
    width: 1300px;
    height: 50px;
    margin-left: 15px;
  }
  .comment_write_under_profile {
    min-width: 100px;
    height: 20px;
    font-size: 17px;
    padding-left: 0px;
  }
  .comment_write_under_input {
    width: 1000px;
    height: 34px;
    font-size: 17px;
  }
  .comment_write_under_btn {
    width: 88px;
    height: 34px;
    font-size: 17px;
    /* margin-right: 45px; */
    margin-top: 5px;
  }
  .post_detail_under_flexbox {
    width: 1300px;
    height: 80px;
    margin-left: 15px;
  }
  .post_detail_under_btn {
    width: 118px;
    height: 39px;
    font-size: 17px;
    border: 3px solid #00ee13;
    margin: 0 20px;
  }

  .after_btn_additional_comment {
    width: 90%;
    height: 60px;
    padding-left: 0px;
    display: flex;
    align-items: center;
  }
  .after_btn_additional_comment_nickname {
    background-color: transparent;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    min-width: 100px;
    font-size: 17px;
    height: auto;
    width: 100px;
  }
  .after_btn_additional_comment_content {
    width: 1000px;
    height: 30px;
    font-size: 17px;
    margin-left: 20px;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: 300;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow-x: scroll;
    outline: none;
    background-color: transparent;
    border-bottom: 1px dashed #00ee13;
    border-radius: 0;
    -webkit-appearance: none;
  }
  .after_btn_additional_comment_submit {
    width: 50px;
    margin-left: 40px;
    font-size: 17px;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab;
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    border-bottom: 1px dashed #ff02ff;
    line-height: 2rem;
  }
}
@media screen and (max-width: 1440px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 13vw;
    margin-top: 50px;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
    padding-left: 0px;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 15px;
  }
  .upload_post_list {
    /* width: 97%; */
  }
  .upload_column {
    width: 65px;
    height: 29px;

    /* font-size: 14px; */
  }
  .upload_second_left {
    /* margin-left: 16px; */
  }
  .upload_title {
    /* font-size: 16px; */
    /* margin: 0 5px; */
  }

  .upload_writer {
    /* margin: 0 5px; */
    /* font-size: 16px; */
    /* height: 20px; */
  }
  .upload_image {
    /* width: 20px; */
    /* height: 20px; */
    /* border-radius: 20px; */
    /* margin-right: 5px; */
  }
  .upload_second_column {
    width: 65px;
    height: 29px;
    /* font-size: 16px; */
  }
  .upload_field {
    /* margin: 0 5px; */
    /* font-size: 16px; */
    height: 29px;
    width: 429px;
  }
  .upload_third_column {
    width: 65px;
    height: 29px;
    /* font-size: 16px; */
  }
  .upload_fourth_column {
    width: 65px;
    height: 29px;
    /* font-size: 16px; */
  }
  .upload_fifth_column {
    width: 80px;
    height: 29px;
    font-size: 16px;
    margin: 0 5px;
  }
  .upload_link {
    /* font-size: 16px; */
    /* padding-bottom: 0; */
    /* margin-left: 10px; */
    /* height: 21px; */
  }
  .upload_time_createdAt {
    /* width: 150px;
    height: 20px;
    font-size: 16px; */
  }
  .upload_attach_span {
    /* width: 100px; */
    /* height: 20px; */
    /* font-size: 16px; */
  }
  .upload_quotedCost {
    /* margin: 0 5px; */
    /* font-size: 16px; */
    /* height: 20px; */
    /* width: 20vw; */
  }
  .upload_desc_column {
    /* width: 80px; */
    /* height: 20px; */
    /* font-size: 16px; */
  }
  .upload_description {
    /* margin-left: 5px; */
    /* width: 80%; */
    /* height: 25vh; */
    /* font-size: 16px; */
    /* line-height: 1.3rem; */
  }
  .upload_desc_image {
    /* width: 150px;
    height: 150px; */
    /* width: 100%; */
    /* height: 450px; */
  }
  #isliked_container {
    width: 95%;
    height: 8%;
  }
  #isliked_box {
    width: 100px;
    height: 100%;
  }
  #islikedCount {
    width: 40px;
    height: 20px;
    font-size: 16px;
  }
  #islikedImg {
    width: 50px;
    height: 50px;
  }
  #comment_list_container {
    /* padding-left: 0px; */
    width: 97%;
  }
  .comment_list {
    /* height: 30px; */
    margin-bottom: 10px;
    /* padding-left: 3px; */
  }
  .comment_writer {
    /* width: 13vw; */
    height: auto;
    min-width: 60px;
    width: 105px;
    /* font-size: 16px; */
    /* height: 30px; */
  }
  .comment_content {
    width: 750px;
    /* height: 30px; */

    /* font-size: 16px; */
    /* padding-left: 10px; */
  }
  .comment_createdAt {
    width: 150px;
    /* padding-left: 11px; */
    /* height: 30px; */
    /* font-size: 16px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    /* font-size: 16px; */
  }
  .renewal_btn_comment {
    /* width: 25px; */

    /* padding-left: 5px; */
    /* font-size: 16px; */
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    width: 90%;

    /* height: 30px; */
    /* margin: 0 auto; */
  }
  .additional_comment_writer {
    width: 100px;
    /* width: 10vw; */
    /* font-size: 16px; */
    /* height: 30px; */
  }
  .additional_comment_img {
    margin-left: 0px;
    width: 25px;
    height: 25px;
  }
  .additional_comment_content {
    width: 700px;

    /* font-size: 16px; */
    /* padding-top: 0px; */
    /* height: 30px; */
  }
  .additional_comment_createdAt {
    /* width: 9vw; */
    /* height: 30px; */
    /* padding-top: 0px; */
    /* font-size: 16px; */
    /* margin-left: 14px; */
  }
  .additional_comment_renewalbtn {
    /* font-size: 16px; */
    /* margin-left: 8px; */
    /* margin-top: 2px; */
  }
  #comment_write_under_con {
    width: 97%;
    /* height: 7%; */
  }
  .comment_write_under_profile {
    /* width: 10vw; */
    /* height: 20px; */
    /* font-size: 16px; */
    /* padding-left: 3px; */
  }
  .comment_write_under_input {
    width: 60vw;
    /* width: 55vw; */
    /* height: 20px; */
    /* font-size: 16px; */
  }
  .comment_write_under_btn {
    /* width: 80px; */
    /* height: 25px; */
    /* font-size: 16px; */
    /* margin-right: 45px; */
    /* margin-top: 0px; */
  }
  .post_detail_under_flexbox {
    width: 97%;
    /* height: 80px; */
    /* margin-left: 15px; */
  }
  .post_detail_under_btn {
    /* margin-top: 15px; */
    /* width: 95px; */
    /* height: 30px; */
    /* font-size: 16px; */
    /* border: 1px solid #00ee13; */
  }

  .after_btn_additional_comment {
    /* width: 100%; */
    /* height: 30px; */
    /* padding-left: 30px; */
    /* display: flex; */
    /* align-items: center; */
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    /* width: 10.5vw; */
    /* font-size: 16px; */
    /* height: 30px; */
  }
  .after_btn_additional_comment_content {
    width: 900px;
    /* height: 30px; */
    /* font-size: 16px; */
    /* margin-left: 20px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
    /* outline: none; */
    /* background-color: transparent; */
    /* border-bottom: 1px solid #00ee13; */
    /* border-radius: 0; */
    /* -webkit-appearance: none; */
  }
  .after_btn_additional_comment_submit {
    /* margin-left: 27px; */
    /* font-size: 16px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    /* border-bottom: 1px dashed #ff02ff; */
  }
}
@media screen and (max-width: 1024px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
  }
  #post_uploadUl {
    padding: 0px;
    margin-top: 15px;
    padding-left: 20px;
  }
  .upload_column {
    width: 87px;
    /* height: 29px; */
    /* font-size: 12px; */
  }
  .upload_title {
    /* font-size: 15px; */
    /* margin: 0 5px; */
  }
  .upload_writer {
    /* margin: 0 5px; */
    /* font-size: 15px; */
    /* height: 20px; */
  }
  /* .upload_time_createdAt {
    width: 25%;
    height: 20px;
    padding-top: 3px;
    font-size: 15px;
  } */
  .upload_image {
    /* width: 20px; */
    /* height: 20px; */
    /* border-radius: 20px; */
    /* margin-right: 5px; */
  }
  .upload_second_column {
    width: 120px;
    /* font-size: 12px; */
    /* height: 20px; */
    /* font-size: 15px; */
  }
  .upload_field {
    /* margin: 0 5px; */
    /* font-size: 15px; */
    /* height: 20px; */
    /* width: 20vw; */
  }
  .upload_third_column {
    width: 70px;
    /* width: 60px; */
    /* height: 20px; */
    /* font-size: 15px; */
  }
  .upload_link {
    /* font-size: 15px; */
    /* padding-bottom: 0; */
    /* margin-left: 10px; */
    /* height: 21px; */
  }
  .upload_quotedCost {
    /* margin: 0 5px; */
    /* font-size: 15px; */
    /* height: 20px; */
    /* width: 20vw; */
  }
  .upload_desc_column {
    width: 87px;
    /* height: 20px; */
    /* font-size: 15px; */
  }
  .upload_description {
    /* margin-left: 5px; */
    /* width: 80%; */
    /* height: 20vh; */
    /* font-size: 16px; */
    /* line-height: 1.2rem; */
  }
  .upload_desc_image {
    /* width: 100%; */
    /* height: 300px; */
    /* width: 100px;
    height: 100px; */
  }
  #isliked_container {
    /* width: 98%; */
    /* height: 8%; */
  }
  #isliked_box {
    /* width: 12%; */
    /* height: 100%; */
  }
  #islikedCount {
    width: 40px;
    height: 20px;
    font-size: 15px;
  }
  #islikedImg {
    width: 6vh;
    height: 6vh;
  }
  #comment_list_container {
    padding-left: 5px;
  }
  .comment_list {
    /* height: 30px; *
    /
    /* margin-bottom: 5px; */
    /* padding-left: 3px; */
  }
  .comment_writer {
    width: 120px;

    /* width: 13vw; */
    /* font-size: 15px; */
    /* height: 30px; */
  }
  .comment_content {
    width: 50vw;

    /* height: 30px; */
    /* font-size: 15px; */
    /* padding-left: 10px; */
  }
  .comment_createdAt {
    /* width: 13vw; */
    /* padding-left: 11px; */
    /* height: 30px; */
    /* font-size: 15px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    /* font-size: 15px; */
  }
  .renewal_btn_comment {
    /* width: 25px; */
    /* padding-left: 5px; */
    /* font-size: 15px; */
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    width: 90%;

    /* height: 30px; */
    /* margin: 0 auto; */
  }
  .additional_comment_writer {
    /* width: 12vw; */
    /* font-size: 15px; */
    /* height: 30px; */
  }
  .additional_comment_img {
    /* margin-left: 0px; */
    /* width: 17px; */
    /* height: 17px; */
  }
  .additional_comment_content {
    width: 40vw;

    /* font-size: 15px; */
    /* padding-top: 0px; */
    /* height: 30px; */
  }
  .additional_comment_createdAt {
    padding-left: 8px;
    /* width: 10vw; */
    /* height: 30px; */
    /* padding-top: 0px; */
    /* font-size: 15px; */
    /* margin-left: 7px; */
  }
  .additional_comment_renewalbtn {
    /* font-size: 15px; */
    /* margin-left: 20px; */
    /* margin-top: 2px; */
  }
  #comment_write_under_con {
    /* width: 100%; */
    /* height: 5%; */
  }
  .comment_write_under_profile {
    /* width: 15vw; */
    /* height: 20px; */
    /* font-size: 15px; */
    /* padding-left: 3px; */
  }
  .comment_write_under_input {
    width: 54vw;
    /* height: 20px; */
    /* font-size: 15px; */
  }
  .comment_write_under_btn {
    /* width: 10vw; */
    /* height: 20px; */
    /* font-size: 15px; */
    /* margin-right: 15px; */
    /* margin-top: 0px; */
  }
  .post_detail_under_btn {
    /* margin-top: 15px; */
    /* width: 95px; */
    /* height: 30px; */
    /* font-size: 15px; */
    /* border: 1px solid #00ee13; */
  }

  .after_btn_additional_comment {
    /* width: 100%; */
    /* height: 30px; */
    /* padding-left: 32.5px; */
    /* display: flex; */
    /* align-items: center; */
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    /* width: 10.5vw; */
    /* font-size: 15px; */
    /* height: 30px; */
  }
  .after_btn_additional_comment_content {
    width: 48vw;
    /* height: 30px; */
    /* font-size: 15px; */
    /* margin-left: 20px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
    /* outline: none; */
    /* background-color: transparent; */
    /* border-bottom: 1px solid #00ee13; */
    /* border-radius: 0; */
    /* -webkit-appearance: none; */
  }
  .after_btn_additional_comment_submit {
    /* margin-left: 20px; */
    /* font-size: 15px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    /* border-bottom: 1px dashed #ff02ff; */
  }
}

@media screen and (max-width: 768px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 8vw;
    margin-top: 50px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 10px;
    margin-top: 30px;
  }
  .upload_column {
    /* width: 50px; */
    /* height: 20px; */
    font-size: 13px;
  }
  .upload_title {
    /* font-size: 14px; */
    /* margin: 0 5px; */
  }
  .upload_writer {
    /* margin: 0 5px; */
    /* font-size: 14px; */
    /* height: 20px; */
  }
  .upload_time_createdAt {
    /* width: 25%; */
    /* height: 20px; */
    /* padding-top: 2px; */
    /* font-size: 14px; */
  }
  .upload_image {
    /* width: 20px; */
    /* height: 20px; */
    /* border-radius: 20px; */
    /* margin-right: 5px; */
  }

  .upload_second_column {
    /* width: 55px; */
    /* height: 20px; */
    font-size: 13px;
  }
  .upload_field {
    /* margin: 0 5px; */
    /* font-size: 14px; */
    /* height: 20px; */
    /* width: 20vw; */
  }
  .upload_third_column {
    width: 70px;
    /* height: 20px; */
    font-size: 13px;
  }
  .upload_link {
    /* font-size: 13px; */
    /* height: 21px; */
    /* padding-bottom: 20px; */
    /* margin-left: 10px; */
  }
  .upload_quotedCost {
    /* margin: 0 5px; */
    /* font-size: 14px; */
    /* height: 20px; */
    /* width: 20vw; */
  }
  .upload_desc_column {
    /* width: 55px; */
    /* height: 20px; */
    font-size: 13px;
  }
  .upload_description {
    /* margin-left: 5px; */
    /* width: 80%; */
    /* height: 250px; */
    /* font-size: 14px; */
    /* line-height: 1.05rem; */
  }
  .upload_desc_image {
    /* width: 100%; */
    /* height: 450px; */
    /* width: 150px; */
    /* height: 150px; */
  }
  #isliked_container {
    /* width: 100%; */
    /* height: 8%; */
  }
  #isliked_box {
    /* width: 15%; */
    /* height: 100%; */
  }
  #islikedCount {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 14px; */
  }
  #islikedImg {
    width: 50px;
    height: 50px;
  }
  #comment_list_container {
    width: 95%;
    /* padding-left: 0px; */
  }
  .comment_list {
    /* height: 30px; */
    /* margin-bottom: 5px; */
    /* padding-left: 3px; */
  }
  .comment_writer {
    width: 130px;
    /* width: 13vw; */
    /* font-size: 14px; */
    /* height: 30px; */
  }
  .comment_content {
    /* width: 45vw; */
    /* height: 30px; */
    /* font-size: 14px; */
    /* padding-left: 10px; */
  }
  .comment_createdAt {
    /* width: 13vw; */
    /* padding-left: 11px; */
    /* height: 30px; */
    /* font-size: 14px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    /* font-size: 14px; */
  }
  .renewal_btn_comment {
    /* width: 25px; */
    /* padding-left: 5px; */
    /* font-size: 14px; */
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    /* width: 90%; */
    /* height: 30px; */
    /* margin: 0 auto; */
  }
  .additional_comment_writer {
    /* width: 12vw; */
    /* font-size: 14px; */
    /* height: 30px; */
  }
  .additional_comment_img {
    /* margin-left: 0px; */
    /* width: 15px; */
    /* height: 15px; */
  }
  .additional_comment_content {
    /* width: 45vw; */
    /* font-size: 14px; */
    /* padding-top: 0px; */
    /* height: 30px; */
  }
  .additional_comment_createdAt {
    width: 130px;
    /* height: 30px; */
    /* padding-top: 0px; */
    /* font-size: 14px; */
    /* margin-left: 8px; */
  }
  .additional_comment_renewalbtn {
    /* font-size: 14px; */
    /* margin-left: 20px; */
    /* margin-top: 2px; */
  }
  #comment_write_under_con {
    width: 95%;
    /* height: 5%; */
  }
  .comment_write_under_profile {
    /* width: 15vw; */
    /* height: 20px; */
    /* font-size: 14px; */
    /* padding-left: 3px; */
  }
  .comment_write_under_input {
    width: 300px;
    /* height: 20px; */
    /* font-size: 14px; */
  }
  .comment_write_under_btn {
    /* width: 80px; */
    /* height: 50%; */
    /* font-size: 14px; */
    /* margin-right: 15px; */
    /* margin-top: 5px; */
  }
  .post_detail_under_btn {
    /* margin-top: 15px; */
    /* width: 95px; */
    /* height: 30px; */
    /* font-size: 14px; */
    /* border: 1px solid #00ee13; */
  }

  .after_btn_additional_comment {
    /* width: 100%; */
    /* height: 30px; */
    /* padding-left: 32.5px; */
    /* display: flex; */
    /* align-items: center; */
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: scroll;
    white-space: nowrap;
    width: 10vw;
    font-size: 14px;
    height: 30px; */
  }
  .after_btn_additional_comment_content {
    /* width: 50vw;
    height: 30px;
    font-size: 14px;
    margin-left: 20px;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: 600;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    overflow-x: scroll;
    outline: none;
    background-color: transparent;
    border-bottom: 1px solid #00ee13;
    border-radius: 0;
    -webkit-appearance: none; */
  }
  .after_btn_additional_comment_submit {
    /* margin-left: 20px;
    font-size: 14px;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    border-bottom: 1px dashed #ff02ff;
  }
}

@media screen and (max-width: 425px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 5px;
    margin-top: 15px;
  }
  .upload_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .upload_title {
    width: auto;
    font-size: 12px;
    margin: 0 5px;
    white-space: normal;
    overflow: none;
    height: auto;
    line-height: 1.3rem;
    padding-bottom: 10px;
  }
  #count_comment {
    font-size: 11px;
    font-weight: bold;
    color: #ff02ff;
    width: 9px;
    height: 24px;
    margin-left: 5px;
  }
  .upload_post_list {
    flex-direction: column;
  }
  .upload_post_list_second {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .upload_writer {
    width: 150px;
    margin: 0 5px;
    font-size: 12px;
    font-weight: bold;
    /* margin: 0 5px; */
    /* font-size: 13px; */
    /* height: 20px; */
  }
  .upload_time_createdAt {
    /* width: 150px;
    font-size: 17px;
    height: 29px;
    padding-bottom: 5px; */
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }
  .upload_image {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    margin-right: 5px;
    border: 1px solid #00ee13;
  }
  .upload_second_column {
    /* width: 40px; */
    /* height: 20px; */
    /* font-size: 8px; */
  }
  .upload_field {
    margin: 5px 5px;
    font-size: 12px;
    height: 20px;
    width: auto;
    white-space: normal;
    overflow: none;
    height: auto;
    padding-bottom: 5px;

    line-height: 1.3rem;
  }
  .upload_third_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .upload_link {
    margin: 5px 5px;
    font-size: 12px;
    width: auto;
    white-space: normal;
    /* padding-bottom: 15px; */
    overflow: auto;
    border-bottom: 1px dashed #ff02ff;
    height: auto;
    line-height: 1.3rem;
  }
  .upload_quotedCost {
    margin: 5px 5px;
    font-size: 12px;
    height: 20px;
    width: auto;
    white-space: normal;
    overflow: none;
    height: auto;
    line-height: 1.3rem;
    padding-bottom: 5px;
  }
  .upload_desc_column {
    /* width: 13%; */
    /* height: 20px; */
    /* font-size: 8px; */
  }
  .upload_description {
    margin-left: 5px;
    width: 100%;
    font-size: 12px;
    /* height: 150px; */
    /* font-size: 13px; */
    /* line-height: 1.05rem; */
  }
  .upload_desc_image {
    width: 100%;
    /* height: 300px; */
    /* width: 100px; */
    /* height: 100px; */
  }
  #isliked_container {
    /* width: 100%; */
    /* height: 8%; */
  }
  #isliked_box {
    /* width: 20%; */
    /* height: 100%; */
  }
  #islikedCount {
    /* width: 15px; */
    /* height: 10px; */
    font-size: 12px;
  }
  #islikedImg {
    width: 25px;
    height: 25px;
  }
  #comment_list_container {
    /* padding: 0; */
    margin-left: 8px;
    /* padding-left: 0px; */
  }
  .comment_list {
    /* height: 25px; */
    /* margin-bottom: 5px; */
    /* padding: 0px; */
    /* padding-left: 3px; */
  }
  .comment_writer {
    font-size: 12px;
    min-width: 75px;
    width: 60px;
    line-height: 1rem;
    height: auto;
    /* height: 25px; */
  }
  .comment_content {
    width: 180px;
    /* height: 25px; */
    font-size: 12px;
    padding: 0;
    line-height: 1rem;
    /* padding-left: 10px; */
  }
  .comment_createdAt {
    /* width: 60px; */
    /* padding-left: 11px; */
    /* height: 25px; */
    /* font-size: 13px; */
  }
  .upload_time_createdAt {
    /* width: 25%; */
    /* height: 20px; */
    /* padding-top: 2px; */
    /* font-size: 13px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    font-size: 9px;
    width: 20px;
  }
  .renewal_btn_comment {
    width: 20px;
    /* width: 15px; */
    /* padding-left: 0px; */
    /* margin-left: 10px; */
    font-size: 9px;
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    width: 100%;
    /* height: 25px; */
    /* margin: 0 auto; */
    /* padding: 0; */
    margin: 2px 3px;
  }
  .additional_comment_writer {
    /* width: 80px; */
    min-width: 75px;
    width: 60px;
    line-height: 1.2rem;
    font-size: 12px;
    /* height: 25px; */
  }
  .additional_comment_img {
    /* margin-left: 10px; */
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
  .additional_comment_content {
    width: 177px;
    font-size: 12px;
    padding: 0;
    margin: 0;
    line-height: 1.2rem;
    /* height: 25px; */
  }
  .additional_comment_createdAt {
    /* width: 50px; */
    /* height: 15px; */
    /* padding-top: 2px; */
    /* font-size: 13px; */
    /* margin-left: 10px; */
  }
  .additional_comment_renewalbtn {
    font-size: 9px;
    width: 20px;
    padding: 0;
    margin: 0;
    margin-left: 8px;
    /* margin-left: 11px; */
    /* margin-top: 2px; */
  }
  #comment_write_under_con {
    /* width: 100%; */
    /* height: 5%; */
  }
  .comment_write_under_profile {
    /* width: 80px; */
    min-width: 80px;
    font-size: 12px;
    height: 20px;
    /* padding-left: 3px; */
  }
  .comment_write_under_input {
    width: 150px;
    font-size: 12px;
    height: 25px;
  }
  .comment_write_under_btn {
    width: 55px;
    height: 25px;
    font-size: 12px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .post_detail_under_flexbox {
    width: 95%;
    height: 50px;
  }
  .post_detail_under_btn {
    /* margin-top: 15px; */

    width: 65px;
    height: 25px;
    font-size: 12px;
    border: 1px solid #00ee13;
  }

  .after_btn_additional_comment {
    width: 100%;
    height: 25px;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    /* width: 70px; */
    font-size: 12px;
    min-width: 65px;
    height: auto;
  }
  .after_btn_additional_comment_content {
    width: 185px;
    height: 25px;
    font-size: 12px;
    /* margin-left: 7px; */
    color: #00ee13;
    font-family: "Noto Sans KR";
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
    /* outline: none; */
    /* background-color: transparent; */
    /* border-bottom: 1px solid #00ee13; */
    /* border-radius: 0; */
    -webkit-appearance: none;
  }
  .after_btn_additional_comment_submit {
    /* margin-left: 16px; */
    font-size: 9px;
    width: 30px;
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    border-bottom: 1px dashed #ff02ff;
  }
}

@media screen and (max-width: 375px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 80vh;
    margin-left: 25px;
    margin-top: 30px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 5px;
  }
  .upload_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .upload_title {
    /* font-size: 12px; */
    /* margin: 0 5px; */
  }
  .upload_writer {
    /* margin: 0 5px; */
    /* font-size: 12px; */
    /* height: 20px; */
  }
  .upload_image {
    /* width: 18px; */
    /* height: 18px; */
    /* border-radius: 18px; */
    /* margin-right: 5px; */
  }
  .upload_second_column {
    /* width: 40px; */
    /* height: 20px; */
    /* font-size: 7px; */
  }
  .upload_field {
    /* margin: 0 5px; */
    /* font-size: 12px; */
    /* height: 20px; */
    /* width: 80px; */
  }
  .upload_third_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 7px; */
  }
  .upload_link {
    /* width: 100px; */
    /* font-size: 12px; */
    /* height: 21px; */
    /* padding-bottom: 13px; */
    /* margin-left: 3px; */
    /* margin-right: 5px; */
  }
  .upload_quotedCost {
    /* margin: 0 5px; */
    /* font-size: 12px; */
    /* height: 20px; */
    /* width: 80px; */
  }
  .upload_desc_column {
    /* width: 13%; */
    /* height: 20px; */
    /* font-size: 7px; */
  }
  .upload_description {
    /* margin-left: 5px; */
    /* width: 80%; */
    /* height: 150px; */
    /* font-size: 12px; */
    /* line-height: 1.05rem; */
  }
  .upload_desc_image {
    /* width: 100px; */
    /* height: 100px; */
    /* width: 100%; */
    /* height: 300px; */
  }
  #isliked_container {
    /* width: 100%; */
    /* height: 8%; */
  }
  #isliked_box {
    /* width: 20%; */
    /* height: 100%; */
  }
  #islikedCount {
    /* width: 15px; */
    /* height: 10px; */
    /* font-size: 10px; */
  }
  #islikedImg {
    width: 25px;
    height: 25px;
  }
  #comment_list_container {
    /* padding-left: 0px; */
  }
  .comment_list {
    /* height: 25px; */
    /* margin-bottom: 5px; */
    /* padding: 0px; */
    /* padding-left: 3px; */
  }
  .comment_writer {
    /* width: 75px; */
    /* font-size: 12px; */
    /* height: 25px; */
  }
  .comment_content {
    /* width: 140px; */
    width: 150px;
    /* height: 25px; */
    /* font-size: 12px; */
    /* padding-left: 10px; */
  }
  .comment_createdAt {
    /* width: 60px; */
    /* padding-left: 0px; */
    /* height: 25px; */
    /* font-size: 12px; */
    /* margin-left: 3px; */
  }
  .upload_time_createdAt {
    /* width: 25%; */
    /* height: 20px; */
    /* padding-top: 3.7px; */
    /* font-size: 12px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    /* font-size: 12px; */
  }
  .renewal_btn_comment {
    /* width: 15px; */
    /* margin-left: 10px; */
    /* padding-left: 0px; */
    /* font-size: 12px; */
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    /* width: 80%; */
    /* height: 25px; */
    /* margin: 0 auto; */
  }
  .additional_comment_writer {
    /* width: 65px; */
    /* font-size: 12px; */
    /* height: 25px; */
  }
  .additional_comment_img {
    /* margin-left: 10px; */
    /* width: 13px; */
    /* height: 13px; */
    margin-right: 3px;
  }
  .additional_comment_content {
    width: 150px;
    /* font-size: 12px; */
    /* padding-top: 1px; */
    /* height: 25px; */
  }
  .additional_comment_createdAt {
    /* width: 50px; */
    /* height: 15px; */
    /* padding-top: 0px; */
    /* font-size: 12px; */
    /* margin-left: 10px; */
  }
  .additional_comment_renewalbtn {
    /* font-size: 12px; */
    /* margin-left: 19px; */
    /* margin-top: 2px; */
    margin-left: 4px;
    height: 21px;
  }
  #comment_write_under_con {
    width: 93%;
    /* height: 5%; */
  }
  .comment_write_under_profile {
    /* width: 70px; */
    /* padding-left: 3px; */
    /* font-size: 12px; */
  }
  .comment_write_under_input {
    width: 125px;
    /* font-size: 12px; */
  }
  .comment_write_under_btn {
    /* width: 60px; */
    /* height: 70%; */
    /* font-size: 12px; */
    /* margin-right: 5px; */
    /* margin-top: 5px; */
  }
  .post_detail_under_flexbox {
    width: 90%;
    height: 50px;
  }
  .post_detail_under_btn {
    /* margin-top: 15px; */
    /* width: 60px; */
    /* height: 23px; */
    /* font-size: 12px; */
    /* border: 1px solid #00ee13; */
  }

  .after_btn_additional_comment {
    /* width: 100%; */
    /* height: 25px; */
    /* padding-left: 29px; */
    /* display: flex; */
    /* align-items: center; */
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    /* width: 70px; */
    /* font-size: 12px; */
    /* height: 25px; */
  }
  .after_btn_additional_comment_content {
    /* width: 138px; */
    /* height: 25px; */
    /* font-size: 12px; */
    /* margin-left: 7px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
    /* -webkit-appearance: none; */
    /* outline: none; */
    /* background-color: transparent; */
    /* border-bottom: 1px solid #00ee13; */
    /* border-radius: 0; */
    /* -webkit-appearance: none; */
  }
  .after_btn_additional_comment_submit {
    /* margin-left: 8px; */
    /* font-size: 12px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    border-bottom: 1px dashed #ff02ff;
  }
}

@media screen and (max-width: 320px) {
  #post_detaillayout {
    position: absolute;
    top: 0px;
    width: 85vw;
    height: 85vh;
    margin-left: 25px;
    margin-top: 30px;
    border: 4px solid #00ee13;
  }
  #post_IntroDetail {
    width: 100%;
  }
  #post_UploadBox {
    width: 100%;
  }
  #post_uploadUl {
    padding: 0px;
    padding-left: 5px;
  }
  .upload_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 9px; */
  }
  .upload_title {
    /* font-size: 11px; */
    /* margin: 0 5px; */
    /* padding-top: 3px; */
  }
  .upload_writer {
    /* margin: 0 5px; */
    /* font-size: 11px; */
    /* height: 20px; */
  }
  .upload_image {
    /* width: 15px; */
    /* height: 15px; */
    /* border-radius: 13px; */
    /* margin-right: 5px; */
  }
  .upload_second_column {
    /* width: 40px; */
    /* height: 20px; */
    /* font-size: 6px; */
  }
  .upload_field {
    /* margin: 0 5px; */
    /* font-size: 11px; */
    /* height: 20px; */
    /* width: 80px; */
  }
  .upload_third_column {
    /* width: 30px; */
    /* height: 20px; */
    /* font-size: 7px; */
  }
  .upload_link {
    /* width: 100px; */
    /* font-size: 11px; */
    /* padding-bottom: 7px; */
    /* margin-left: 5px; */
  }
  .upload_quotedCost {
    /* margin: 0 5px; */
    /* font-size: 11px; */
    /* height: 20px; */
    /* width: 80px; */
  }
  .upload_desc_column {
    /* width: 13%; */
    /* height: 20px; */
    /* font-size: 7px; */
  }
  .upload_description {
    /* margin-left: 5px; */
    /* width: 80%; */
    /* height: 105px; */
    /* font-size: 11px; */
    /* line-height: 1.05rem; */
  }
  .upload_desc_image {
    /* width: 100%; */
    /* height: 240px; */
    /* width: 80px; */
    /* height: 80px; */
  }
  #isliked_container {
    /* width: 100%; */
    /* height: 8%; */
  }
  #isliked_box {
    /* width: 20%; */
    /* height: 100%; */
  }
  #islikedCount {
    /* width: 15px; */
    /* height: 10px; */
    /* font-size: 10px; */
  }
  #islikedImg {
    width: 25px;
    height: 25px;
  }
  #comment_list_container {
    /* padding-left: 0px; */
  }
  .comment_list {
    /* height: 22px; */
    /* margin-bottom: 5px; */
    /* padding: 0px; */
    /* padding-left: 3px; */
  }
  .comment_writer {
    /* width: 70px; */
    /* font-size: 11px; */
    /* height: 22px; */
  }
  .comment_content {
    width: 125px;
    /* width: 120px; */
    /* height: 22px; */
    /* font-size: 11px; */
    /* padding-left: 0px; */
    /* margin-left: 5px; */
  }
  .comment_createdAt {
    /* width: 50px; */
    /* padding-left: 0px; */
    /* height: 22px; */
    /* margin-left: 3px; */
    /* font-size: 11px; */
  }
  .upload_time_createdAt {
    /* width: 25%; */
    /* height: 20px; */
    /* padding-top: 3.7px; */
    /* font-size: 11px; */
  }
  .additionalCommentBtn {
    /* width: 10; */
    /* margin-left: 8px; */
    /* font-size: 11px; */
  }
  .renewal_btn_comment {
    /* width: 15px; */
    /* margin-left: 10px; */
    /* padding-left: 0px; */
    /* font-size: 11px; */
    /* margin: 0 5px; */
  }
  #additionalComment_list {
    /* width: 80%; */
    /* height: 22px; */
    /* margin: 0 auto; */
  }
  .additional_comment_writer {
    /* width: 50px; */
    /* font-size: 11px; */
    /* height: 22px; */
  }
  .additional_comment_img {
    /* margin-left: 10px; */
    width: 15px;
    height: 15px;
  }
  .additional_comment_content {
    width: 105px;
    /* font-size: 11px; */
    /* padding-top: 1px; */
    /* height: 23px; */
  }
  .additional_comment_createdAt {
    /* width: 40px; */
    /* height: 15px; */
    /* padding-top: 0px; */
    /* font-size: 11px; */
    /* margin-left: 10px; */
  }
  .additional_comment_renewalbtn {
    /* font-size: 11px; */
    /* margin-left: 13px; */
    /* margin-top: 2px; */
  }
  #comment_write_under_con {
    /* width: 100%; */
    /* height: 5%; */
  }
  .comment_write_under_profile {
    height: 30px;
    padding-top: 7px;
    /* width: 65px; */
    /* padding-left: 3px; */
    /* font-size: 11px; */
  }
  .comment_write_under_input {
    /* width: 120px; */
    /* font-size: 11px; */
  }
  .comment_write_under_btn {
    width: 30px;
    margin-left: 10px;
    /* height: 70%; */
    /* font-size: 11px; */
    /* margin-right: 5px; */
    /* margin-top: 5px; */
  }
  .post_detail_under_btn {
    /* margin-top: 10px; */
    /* width: 60px; */
    /* height: 20px; */
    /* font-size: 11px; */
    /* border: 1px solid #00ee13; */
  }

  .after_btn_additional_comment {
    /* width: 100%; */
    /* height: 22px; */
    /* padding-left: 21px; */
    /* display: flex; */
    /* align-items: center; */
  }
  .after_btn_additional_comment_nickname {
    /* background-color: transparent; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    /* overflow-x: scroll; */
    /* white-space: nowrap; */
    /* width: 70px; */
    /* font-size: 11px; */
    /* height: 22px; */
  }
  .after_btn_additional_comment_content {
    width: 180px;
    /* height: 22px; */
    /* font-size: 11px; */
    /* margin-left: 7px; */
    /* color: #00ee13; */
    /* font-family: "Noto Sans KR"; */
    /* font-weight: 600; */
    /* display: flex; */
    /* justify-content: flex-start; */
    /* align-items: center; */
    /* white-space: nowrap; */
    /* overflow-x: scroll; */
    /* outline: none; */
    /* background-color: transparent; */
    /* border-bottom: 1px solid #00ee13; */
    /* border-radius: 0; */
    /* -webkit-appearance: none; */
  }
  .after_btn_additional_comment_submit {
    min-width: 20px;
    padding: 0;
    margin: 0;
    margin-left: 10px;
    /* margin-left: 8px;
    font-size: 11px;
    color: #00ee13;
    font-family: "Noto Sans KR";
    font-weight: 600;
    padding-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: grab; */
  }

  .link_me {
    /* border-bottom: 1px dashed #ff02ff; */
  }
  .postdetail_link {
    border-bottom: 1px dashed #ff02ff;
  }
}

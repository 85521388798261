@media screen and (max-width: 2800px) {
  #ErrorRes {
    padding-top: 0;
  }
}
@media screen and (max-width: 1440px) {
  #ErrorRes {
    padding-top: 0;
  }
}
@media screen and (max-width: 1024px) {
  #ErrorRes {
    padding-top: 0;
  }
}

@media screen and (max-width: 768px) {
  #ErrorRes {
    padding-top: 60px;
  }
}

@media screen and (max-width: 425px) {
  #ErrorRes {
    padding-top: 40px;
  }
}

@media screen and (max-width: 375px) {
  #ErrorRes {
    padding-top: 40px;
  }
}

@media screen and (max-width: 320px) {
  #ErrorRes {
    padding-top: 30px;
  }
}

@media screen and (max-width: 2800px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
  }
  .logoBox {
    height: 30vh;
  }
  .headerLogo {
    width: 210px;
    height: 210px;
    margin-bottom: 5px;
    margin-left: 0px;
    background-size: contain;
  }
  .headerul {
    width: 220px;
    height: 500px;
    margin-top: 15px;
    padding-bottom: 10px;
    margin-left: 100px;
    border: solid 6px #00ee13;
  }
  .headeropt {
    height: 70px;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 700;
  }
  .headerNavText {
    width: 40px;
    height: 18px;
    margin-left: 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1440px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
  }
  .logoBox {
    height: 30vh;
  }
  .headerLogo {
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    margin-left: 3px;
    background-size: contain;
  }
  .headerul {
    width: 220px;
    height: 500px;
    margin-top: 15px;
    padding-bottom: 10px;
    margin-left: 100px;
    border: solid 6px #00ee13;
  }
  .headeropt {
    height: 70px;
    margin-left: 20px;
    font-size: 24px;
    font-weight: 700;
  }
  .headerNavText {
    width: 40px;
    height: 18px;
    margin-left: 10px;
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) {
  .HeaderContainer {
    width: 20vw;
    height: 28vh;
  }
  .logoBox {
    height: 30vh;
  }
  .headerLogo {
    width: 200px;
    height: 200px;
    margin-bottom: 5px;
    background-size: contain;
  }
  .headerul {
    width: 190px;
    height: 500px;
    margin-top: 15px;
    padding-bottom: 10px;
    margin-left: 75px;
    border: solid 5px #00ee13;
  }
  .headeropt {
    height: 60px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
  }
  .headerNavText {
    width: 35px;
    height: 15px;
    margin-left: 10px;
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
  }
  .headerLogo {
    width: 200px;
    height: 200px;
    background-size: cover;
  }
  .headerul {
    width: 26vw;
    height: 50vh;
    margin-top: 15px;
    padding-bottom: 10px;
    margin-left: 65px;
    border: solid 5px #00ee13;
  }
  .headeropt {
    height: 60px;
    margin-left: 20px;
    font-size: 20px;
    font-weight: 700;
  }
  .headerNavText {
    width: 35px;
    height: 15px;
    margin-left: 10px;
    font-size: 10px;
  }
}

@media screen and (max-width: 425px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
  }
  .headerLogo {
    width: 100%;
    height: 120px;
    background-size: cover;
  }
  .headerul {
    width: 33vw;
    height: 50vh;
    margin-top: 0px;
    padding-bottom: 10px;
    margin-left: 30px;
    border: solid 4px #00ee13;
  }
  .headeropt {
    height: 40px;
    margin-top: 5px;
    margin-left: 15px;
    font-size: 3.5vw;
    font-weight: 800;
  }
  .headerNavText {
    width: 30px;
    height: 13px;
    margin-left: 8px;
    font-size: 8px;
  }
}

@media screen and (max-width: 375px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
  }
  .headerLogo {
    width: 100%;
    height: 15vh;
    background-size: cover;
  }
  .headerul {
    width: 33vw;
    height: 50vh;
    margin-top: 8px;
    padding-bottom: 10px;
    margin-left: 20px;
    border: solid 4px #00ee13;
  }
  .headeropt {
    height: 40px;
    margin-top: 5px;
    margin-left: 15px;
    font-size: 3.5vw;
    font-weight: 800;
  }
  .headerNavText {
    width: 30px;
    height: 13px;
    margin-left: 5px;
    font-size: 8px;
  }
}

@media screen and (max-width: 320px) {
  .HeaderContainer {
    width: 30vw;
    height: 30vh;
  }
  .logoBox {
    height: 30vh;
    margin-bottom: 0px;
  }
  .headerLogo {
    width: 100%;
    height: 100px;
    background-size: cover;
  }
  .headerul {
    width: 110px;
    height: 50vh;
    padding-bottom: 10px;
    margin-left: 20px;
    border: solid 3px #00ee13;
  }
  .headeropt {
    height: 30px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 700;
  }
  .headerNavText {
    width: 30px;
    height: 12px;
    margin-left: 5px;
    font-size: 8px;
  }
}
